import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
    TextField,
    Button,
  } from '@mui/material';
  import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});


function Profile() {

const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [level, setLevel] = useState('');
const [extension, setExtension] = useState('');
const { password, showPassword } = useState(false);

const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [username, setUsername] = useState('');
 const [oldpassword, setOldPassword] = useState('');
 const [newpassword, setNewPassword] = useState('');

  const userInfo = () => {
    const accessToken = localStorage.getItem('access_token');
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',};
    api.get('/user/', {
        headers,
        withCredentials: true,
      }).then(response => {
          setName(response.data.user_name);
          setEmail(response.data.user_email);
          setPhone(response.data.user_phone);
          setLevel(response.data.user_level);
          setExtension(response.data.user_extension);})
        .catch(error => {
          console.error(error);
        });}
        
        useEffect(() => {
          userInfo();
        }, []);

        const handleUserSubmit = (event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          api.put('/updateuserprofile/', formData,{ withCredentials: true})
            .then((response) => {
              userInfo();
              window.location.reload();
            })
            .catch((error) => {
              console.error(error);
            });
        };
      
        const handleSubmitPassword = (event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          api.put('/userupdatepass/', formData,{ withCredentials: true})
            .then((response) => {
              setSelectedTab(0);
              setOldPassword('');
              setNewPassword('');
            })
            .catch((error) => {
              console.error(error);
            });
        };
      
        const handleNameChange = (event) => {
          setName(event.target.value);
        };
      
        const handleEmailChange = (event) => {
          setEmail(event.target.value);
        };
      
        const handlePhoneChange = (event) => {
          setPhone(event.target.value);
        };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Profile</Typography>
        <Grid  spacing={2}  p={1} >
        <Grid item xs={12} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>

<Box position="sticky"
    top="-10px">
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Profile"/>
        <Tab  label="Security"/>
      </Tabs>

      </Box>

      {selectedTab === 0 && <span>
        <form onSubmit={handleUserSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={6}>
        <TextField
          label="Name"
          placeholder="Name"
          variant="outlined"
          fullWidth
          name="user_name"
          value={name}
          onChange={handleNameChange}
          required="true"
          type='text'
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Phone Number"
          placeholder="Phone Number"
          variant="outlined"
          fullWidth
          name="user_phone"
          value={phone}
          onChange={handlePhoneChange}
          required="true"
          type='text'
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Email"
          placeholder="Email"
          variant="outlined"
          fullWidth
          name="user_email"
          value={email}
          onChange={handleEmailChange}
          required="true"
          type='email'
          disabled
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Extension"
          placeholder="Extension"
          variant="outlined"
          fullWidth
          name="extension"
          value={extension}
          required="true"
          type='number'
          disabled
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Level"
          placeholder="Level"
          variant="outlined"
          fullWidth
          name="user_level"
          value={level}
          required="true"
          type='text'
          disabled
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Title"
          placeholder="Title"
          variant="outlined"
          fullWidth
          name="title"
          type='text'
          disabled
        />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={(e) => setUsername(e.target.value)}
          type="submit"
          // sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500], display: sessionStorage.getItem("user_level")==='agent' ? 'none' : '',}}
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500],}}

        >
         Update User Info
        </Button>
        </Grid>
        </Grid>
        </form>
            </span>}
            {selectedTab === 1 && <span>
                <form onSubmit={handleSubmitPassword}>
        <Grid container spacing={2} p={2}>

        <Grid item xs={6}>
        <TextField
          label="Old Password"
          placeholder="Old Password"
          variant="outlined"
          fullWidth
          name="old_pass"
          onChange={(e) => setOldPassword(e.target.value)}
          required="true"
          // type='password'
          type={showPassword ? 'text' : 'password'}
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="New Password"
          placeholder="New Password"
          variant="outlined"
          fullWidth
          name="new_pass"
          onChange={(e) => setNewPassword(e.target.value)}
          required="true"
          type='password'
        />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={(e) => setUsername(e.target.value)}
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
         Update Password
        </Button>
        </Grid>
        </Grid>
        </form>
            </span>}
        </Grid>
        </Grid>

  </Box>
  );
}

export default Profile;