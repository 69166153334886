import { useState, useRef, useEffect } from 'react';

export const useTimer = () => {
  const [timer, setTimer] = useState('00:00:00');
  const intervalRef = useRef(null);

  const startTimer = () => {
    const startTime = new Date();
  
    const updateTimer = () => {
      const currentTime = new Date();
      const elapsedTime = currentTime - startTime;
      const seconds = Math.floor((elapsedTime / 1000) % 60);
      const minutes = Math.floor((elapsedTime / 1000 / 60) % 60);
      const hours = Math.floor((elapsedTime / 1000 / 60 / 60) % 24);
      setTimer(
        `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`
      );
    };
    updateTimer();
    // Start the interval to update the timer every second
    intervalRef.current = setInterval(updateTimer, 1000);
  };

  const resetTimer = () => {
    setTimer('00:00:00');
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return { timer, startTimer, resetTimer};
};
