import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
    Modal,
    MenuItem,
    Box,
    Typography,
    Grid,
  } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Scripts = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [scriptopen, setScriptOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [scripts, setScripts] = useState(null);
    const [scripteditopen, setScriptEditOpen] = useState(false);
    const [scripteditopenreadonly, setScriptEditOpenReadonly] = useState(false);

    const [searchScriptValue, setSearchScriptValue] = useState('');
    const [searchScriptResults, setSearchScriptResults] = useState([]);

    
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      }

const api = axios.create({baseURL: global_link});

const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleScriptSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addscript/', formData,{ withCredentials: true})
      .then((response) => {
        setScriptOpen(false);
        setScriptResultsUp();
      })
      .catch((error) => {
        console.error(error);
      });
   }


   const handleScriptEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updatescript/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleSearchScriptChange = (event) => {
    const searchvalue = event.target.value;
    setSearchScriptValue(searchvalue);
    const accessToken = localStorage.getItem('access_token');
    const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',};
    api.get('/allscript/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        const scriptresultdata = response.data;
        const filteredScriptResults = scriptresultdata.filter(item =>
          item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.department.toLowerCase().includes(searchvalue.toLowerCase())
        );
      
        setSearchScriptResults(filteredScriptResults);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const setScriptResultsUp = (event) => {
        const searchvalue = "";
        setSearchScriptValue(searchvalue);
        const accessToken = localStorage.getItem('access_token');
        const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',};
        api.get('/allscript/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            const scriptresultdata = response.data;
            const filteredScriptResults = scriptresultdata.filter(item =>
              item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.department.toLowerCase().includes(searchvalue.toLowerCase())
            );
          
            setSearchScriptResults(filteredScriptResults);
          })
          .catch(error => {
            console.error(error);
          });
      };

      useEffect(() => {
        setScriptResultsUp();
        },[]);


  const handleRowClick = (item) => {
    setSelectedItem(item);
    setScriptEditOpen(true);
  };

  // const handleRowClickReadOnly = (item) => {
  //   setSelectedItem(item);
  //   setScriptEditOpenReadonly(true);
  // };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };



  const handleScriptDelete = (id) => {
    api.delete(`/deletescript/${id}`, { withCredentials: true })
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        setScriptEditOpen(true);
      });
  };


  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}> Scripts</Typography>
    <Box display="flex" justifyContent="space-between">
<Box>
<Grid>
<Grid item xs={12}>
    <TextField
          placeholder="Search..."
          value={searchScriptValue || ""}
          fullWidth
          onChange={handleSearchScriptChange}
        />
        </Grid>
        </Grid>
    </Box>

    <Box>
    <Button
        variant='contained'
        sx={{
          backgroundColor: colors.blueAccent[600],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          mr: "15px;",
          display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
        }}
        onClick={() => setScriptOpen(true)}
      >
        <AddIcon sx={{ mr: "10px" }} />
        Add Script
      </Button>
      </Box>
    </Box>

    {searchScriptResults.map((script, i) => ( 
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>{script.header} - {script.department}</b></Typography>
      <Button key={script.id} variant='contained' sx={{marginLeft:'20px',backgroundColor: colors.blueAccent[600],display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',}} onClick={() => handleRowClick(script)}><DriveFileRenameOutlineIcon/></Button>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      {script.script}
      </Typography>
    </AccordionDetails>
  </Accordion>
   ))}


    <Modal
        open={scriptopen}
        onClose={() => setScriptOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Script / Reminder</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={ handleScriptSubmit}>


      <TextField
            label="Department"
            placeholder="Department"
            variant="outlined"
            fullWidth
            name="department"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

<TextField
            label="Header"
            placeholder="Header"
            variant="outlined"
            fullWidth
            name="header"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

            <TextField
            multiline
            rows={6}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
            required="true"
          />
          
        
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Script</Button>
          </form>
            </div>
            </Box>
            </Modal>





            
    <Modal
        open={scripteditopen}
        onClose={() => setScriptEditOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
           
<form onSubmit={handleScriptEditSubmit}>
{selectedItem && ( 
    <Grid container spacing={1}>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Department"
        name="department"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.department || ""}
      />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Header"
        name="header"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.header || ""}
      />
</Grid>

<Grid item xs={12}>
            <TextField
            multiline
            rows={8}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputModChange}
            value={selectedItem.script || ""}
            sx={{marginTop:"15px"}}
            required="true"
          />

<TextField
            variant="outlined"
            fullWidth
            name="id"
            onChange={handleInputModChange}
            value={selectedItem.id || ""}
            sx={{display:"none"}}
            required="true"
            type="hidden"
          />
          </Grid>

          <Grid container item xs={12} sx={{display:'flex', justifyContent:'space-between'}}>

<Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Edit Script</Button>
<Button type="button" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: 'red'}} onClick={() => handleScriptDelete(selectedItem.id)}>Delete</Button>

</Grid>
          </Grid>
          )}


    
          

</form>
            </div>
            </Box>
            </Modal>





            <Modal
        open={scripteditopenreadonly}
        onClose={() => setScriptEditOpenReadonly(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">{selectedItem && (selectedItem.department)} Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpenReadonly(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
            
{selectedItem && ( 
<Grid container spacing={1}>
<Grid item xs={12}><br/>
<h2>{selectedItem.script}</h2>
</Grid></Grid>
          )}

            </div>
            </Box>
            </Modal>





    </Box>
  );
};

export default Scripts;
