import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import KeyIcon from '@mui/icons-material/Key';
import { ColorModeContext, tokens } from "../theme";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Tabs, 
    Tab,
    Divider,
  } from '@mui/material';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

// TOAST NOTIFICATION
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// TOAST NOTIFICATION

const api = axios.create({baseURL: global_link});
function SupSettings() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [newticketopen, setNewTicketOpen] = useState(false);
  const [newticketeditopen, setNewTickeEdittOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);


  // TOAST NOTIFICATION
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false); 
    // TOAST NOTIFICATION

  const [isModalUserOpen, setIsModalUserOpen] = useState(false);
  const [isModalUserEditOpen, setIsModalUserEditOpen] = useState(false);
  const [isModalUserSecurityOpen, setIsModalUserSecurityOpen] = useState(false);



  const [selectedUserItem, setSelectedUserItem] = useState(null);
  const [OrgID, setOrgID] = useState('');
  const [OrgName, setOrgName] = useState('');


  // TOAST NOTIFICATION
  const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'center',});
  const { vertical, horizontal} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
// TOAST NOTIFICATION 

  
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenSuccess(false);}; 
  
    const handleErrorClose = (event, reason) => {
      if (reason === 'clickaway') {return;}setOpenError(false);};


  const handleCloseUserModal = () => {
    // setSelectedItem(null);
    setIsModalUserOpen(false);
  };
  
  const handleCloseUserEditModal = () => {
    setSelectedUserItem(null);
    setIsModalUserEditOpen(false);
  };

  const handleCloseUserEditPassModal = () => {
    setSelectedUserItem(null);
    setIsModalUserSecurityOpen (false)
  };
  

  const handleEditUserSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('user_id');
    api.put(`/updateuser/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchUserData();
        setIsModalUserEditOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
    }
  
const handleEditUserPasswordSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/supupdateuserpass/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        alert(response.data)
        fetchUserData();
        setIsModalUserSecurityOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
    }
  
    const handleInputUserModChange = (event) => {
      const { name, value } = event.target;
      setSelectedItem((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
    };
  
    const handleUserDelete = (id) => {
        api.delete(`/supdeleteuser/${id}`, { withCredentials: true })
          .then((response) => {
            fetchUserData();
            setIsModalUserEditOpen(false);
            setOpenSuccess(true);
            setOpenError(false);
          })
          .catch((error) => {
            setOpenSuccess(false);
            setOpenError(true);
          });
        };

        const [searchText, setSearchText] = useState('');
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const [filteredData, setFilteredData] = useState([]);
        const [totalItems, setTotalItems] = useState(0);
        const [controller, setController] = useState({
          page: 1,
          rowsPerPage: 10
        });
      

    const fetchUserData = async () => {
        try {
            const accessToken = localStorage.getItem("access_token");
            const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            };
            const response = await api.get('/supervisorusers/',
            { params: {
                search: searchText,
                page: controller.page,
                size: controller.rowsPerPage,
            },
                headers,
                withCredentials: true,
            }
            );
            const data = response.data;
            setFilteredData(data.results);
            setTotalItems(data.count);
        } catch (error) {
            console.error(error);
        }
        };
    
        useEffect(() => {
        fetchUserData();
        }, [searchText, controller]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUserRowClick = (item) => {
    setSelectedUserItem(item);
    setIsModalUserEditOpen(true);
  };

  const handleSecurityRowClick = (item) => {
    setSelectedUserItem(item);
    setIsModalUserSecurityOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

      const handleAddUserSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addusersup/', formData,{ withCredentials: true})
          .then((response) => {
            fetchUserData();
            setNewTicketOpen(false)
            setOpenSuccess(true);
            setOpenError(false);
          })
          .catch((error) => {
            console.error(error);
            setOpenSuccess(false);
            setOpenError(true);
          });
      };


      const handleInputUserEditModChange = (event) => {
        const { name, value } = event.target;
        setSelectedUserItem((prevFormState) => ({
          ...prevFormState,
          [name]: value,
        }));
      };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3}}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Settings</Typography>
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        {/* <Tab label="Users" onClick={fetchTicketData} /> */}
        <Tab label="Users"/>
      </Tabs>
      <Divider/>

      {selectedTab === 0 && (
        <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
          <Typography variant="body1">
            <Box display='flex' justifyContent='right'>


<Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setNewTicketOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> Add User</Button>
              </Box>
              <div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Extension</TableCell> 
              <TableCell>Level</TableCell>
              <TableCell>Reg Date</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Security</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.user_phone}</TableCell>
                  <TableCell>{row.user_email}</TableCell>
                  <TableCell>{row.user_extension}</TableCell>
                  <TableCell>{row.user_level}</TableCell>
                  <TableCell>{row.user_reg_date}</TableCell>
                  <TableCell sx={{display: row.user_level==='admin' ? 'none' : '',}}><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600] }}  onClick={() => handleUserRowClick(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                  <TableCell sx={{display: row.user_level==='admin' ? 'none' : '',}}><Button key={row.id} variant='contained' sx={{backgroundColor: 'orange' }}  onClick={() => handleSecurityRowClick(row)}><KeyIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>
        </Typography>



        <Modal
        open={newticketopen}
        onClose={() => setNewTicketOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add User</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewTicketOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
            <form onSubmit={handleAddUserSubmit}>
        
        <Grid container spacing={1}>

<Grid item xs={6}>
          <TextField
            label="Name"
            placeholder="Name"
            name="user_name"
            onChange={handleInputChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
          />
</Grid>

<Grid item xs={6}>
          <TextField
            label="Email"
            placeholder="Email"
            name="user_email"
            onChange={handleInputChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
          />
</Grid>

<Grid item xs={6}>
          <TextField
            label="Phone Number"
            placeholder="Phone Number"
            name="user_phone"
            onChange={handleInputChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
          />
</Grid>

<Grid item xs={6}>
          <TextField
            label="Password"
            placeholder="Password"
            name="user_pass"
            onChange={handleInputChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
            type="password"
          />
</Grid>


<Grid item xs={12}>
<TextField
  fullWidth
  select
  label="Level"
  name="user_level"
  sx={{ marginTop: "10px" }}
  onChange={handleInputUserModChange}
  required="true"
>
  <MenuItem value="">Select Level</MenuItem>
  <MenuItem value="agent">Agent</MenuItem>
  <MenuItem value="supervisor">Supervisor</MenuItem>
</TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Add User</Button>
          </Grid>

          </Grid> 
        </form>
        </div>
        </Box>
      </Modal>











      <Modal
        open={isModalUserEditOpen} 
        onClose={handleCloseUserEditModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit User</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalUserEditOpen (false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleEditUserSubmit}>
          {selectedUserItem && (
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              placeholder="Name"
              name="user_name"
              onChange={handleInputUserEditModChange}
              value={selectedUserItem.user_name || ""}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />

<TextField
              
              name="user_id"
              onChange={handleInputUserEditModChange}
              value={selectedUserItem.user_id || ""}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="user_email"
              value={selectedUserItem.user_email || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              name="user_phone"
              value={selectedUserItem.user_phone || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Level"
    name="user_level"
    value={selectedUserItem.user_level || ""}
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserEditModChange}
    required="true"
  >
    <MenuItem value="">Select Level</MenuItem>
    <MenuItem value="agent">Agent</MenuItem>
    <MenuItem value="supervisor">Supervisor</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Edit User</Button>
            <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:"#ED0800 !important",color:"white"}} onClick={() => handleUserDelete(selectedUserItem.id)}>Delete</Button>
            </Grid>

            </Grid> )}
          </form>
        </div>
        </Box>
        
      </Modal>  











      <Modal
        open={isModalUserSecurityOpen} 
        onClose={handleCloseUserEditPassModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit User Password</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalUserSecurityOpen (false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleEditUserPasswordSubmit}>
          {selectedUserItem && (
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              placeholder="Name"
              name="user_name"
              value={selectedUserItem.user_name || ""}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readonly
            />

<TextField
              
              name="id"
              value={selectedUserItem.id || ""}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="user_email"
              value={selectedUserItem.user_email || ""}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readonly
            />
</Grid>



<Grid item xs={12}>
            <TextField
              label="Password"
              placeholder="Password"
              name="user_pass"
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              type="password"
            />
</Grid>



<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Update User Password</Button>
            </Grid>

            </Grid> )}
          </form>
        </div>
        </Box>
        
      </Modal>  





</Box>)}


    </div>


{/* TOAST NOTIFICATION */}
<Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openError} autoHideDuration={4000} onClose={handleErrorClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
Error Occured !!!
  </Alert>
  </Snackbar>

  <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
  Success 
  </Alert>
  </Snackbar>
  </Stack>
  {/* TOAST NOTIFICATION */}

  </Box>
  );
}

export default SupSettings;

