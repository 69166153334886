import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SendIcon from '@mui/icons-material/Send';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Autocomplete,
  } from '@mui/material';
  import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ColorModeContext, tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import EmailIcon from '@mui/icons-material/Email';
import CancelIcon from '@mui/icons-material/Cancel';

const api = axios.create({baseURL: global_link});


function Email() {


const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [formData, setFormData] = useState({});
const [searchEmailResults, setSearchEmailResults] = useState([]);
const [searchEmailValue, setSearchEmailValue] = useState("");
const [formDisplay, setFormDisplay] = useState('none');
const [EmailName, setEmailName] = useState('');
const [EmailId, setEmailId] = useState('');
const [EmailResults, setEmailResults] = useState([]);
const [NewEmailOpen, setNewEmailOpen] = useState(false);
const [ModalEmailAddOpen, setModalEmailAddOpen] = useState(false);
const [ModalEmailDeleteOpen, setModalEmailDeleteOpen] = useState(false);
const [inputValue, setInputValue] = useState('');
const [MailIdSend, setMailIdSend] = useState('');
const [MailSendBtn, setMailSendBtn] = useState('');
const [emailType, setEmailType] = useState('');



const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};


const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [username, setUsername] = useState('');


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


   useEffect(() => {
    const emailvalue = "";
    setSearchEmailValue(emailvalue);
    const params = {search : emailvalue}
    api.post(`/allemails/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const emailresultdata = response.data;
        const filteredEmailResults = emailresultdata.filter(item =>
          item.email.toLowerCase().includes(emailvalue.toLowerCase()) || 
          item.mail_id.toLowerCase().include(emailvalue.toLowerCase())
        );
      
        setSearchEmailResults(filteredEmailResults);
      })
      .catch(error => {
        console.error(error);
      });
    }, []);


  const handleSearchEmailChange = (event) => {
    const emailvalue = event.target.value;
    setSearchEmailValue(emailvalue);
    const params = {search : emailvalue}
    api.post(`/allemails/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const emailresultdata = response.data;
        const filteredEmailResults = emailresultdata.filter(item =>
          item.email.toLowerCase().includes(emailvalue.toLowerCase()) || 
          item.mail_id.toLowerCase().include(emailvalue.toLowerCase())
        );
      
        setSearchEmailResults(filteredEmailResults);
      })
      .catch(error => {
        console.error(error);
      });
  
  };

  const handleEmailClick = (vala,valb) => {
    setEmailName(valb);
    setEmailId(vala);
    setFormDisplay('');
    setEmailType('INBOX');
    const params = {mail_id: vala}
    api.post(`/retrievemails/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const emailresultdata = response.data;
        setEmailResults(emailresultdata || []);
        // scrollToBottom();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const  handleEmailSubmit = (event) => {
    event.preventDefault();
    setNewEmailOpen(false); 
    const formData = new FormData(event.target);
    const message = formData.get('message');
    const subject = formData.get('subject');
    const mcopy = formData.get('copy');
    const mail_id = formData.get('mail_id');
    const recipient_email = formData.get('recipient_email');
    const params = {mail_id: EmailId, message: message, subject: subject, recipient_email: recipient_email, copy: mcopy, mail_id: mail_id, }
    api.post('/sendmail/', params,{withCredentials: true,})
    .then((response) => {
      handleEmailClick(EmailId,EmailName); 
    document.getElementById('sendEmailForm').reset();
    setSelectedEmailAddress("");
    setSelectedEmail([]);
    setMailSendBtn('');
  })
    .catch((error) => {
      console.error(error);});
        }

  const handleNewEmailClick = (EmailId) => {
    setNewEmailOpen(true);
    setMailIdSend(EmailId);
  }
  
  const headerPattern = /^(.*?: .+\r?\n)*\r?\n/g;

  const emailstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };

  const emailaddressstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };



// EMAIL LIST TABLE
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchEmailText, setSearchEmailText] = useState('');
    const [filteredEmailData, setFilteredEmailData] = useState([]);
    const [totalEmailItems, setTotalEmailItems] = useState(0);
    const [emailcontroller, setEmailController] = useState({
    emailpage: 1,
    emailrowsPerPage: 10
    });
      
    
      const fetchEmailData = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/emaillist/',
            { params: {
              search: searchEmailText,
              page: emailcontroller.emailpage,
              size: emailcontroller.emailrowsPerPage,
            },
              headers,
              withCredentials: true,
            }
          );
          const data = response.data;
          setFilteredEmailData(data.results);
          setTotalEmailItems(data.count);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        fetchEmailData();
      }, [searchEmailText, emailcontroller]);
    
    
      const handleEmailRowClick = (item) => {
        setSelectedItem(item);
        setModalEmailDeleteOpen(true);
      };
    
      const handleEmailChangePage = (event, newPage) => {
        setEmailController({
          ...emailcontroller,
          emailpage: newPage
        });
      };
    
      const handleEmailChangeRowsPerPage = (event) => {
        setEmailController({
          ...emailcontroller,
          emailtrowsPerPage: parseInt(event.target.value, 10),
          emailpage: 1
        });
      };


      const [searchText, setSearchText] = useState('');
      const handleAddressSubmit = (event) => {
        event.preventDefault();
        api.post('/addemaillist/', formData,{ withCredentials: true})
          .then((response) => {
            fetchEmailData();
            setModalEmailAddOpen(false);
            allemailsend();
            
          })
          .catch((error) => {
            console.error(error);
          });
      };


      const handleDeleteEmailAddress = (id) => {
        api.delete(`/deleteemailist/${id}`, { withCredentials: true })
        .then((response) => {
          fetchEmailData();
          setModalEmailDeleteOpen(false);
        })
        .catch((error) => {
          console.error(error);
        });
      }

        //SEARCH EMAIL ADDRESSES AUTOCOMPLETE
const [selectedEmail, setSelectedEmail] = useState([]);
const [emailOptions, setEmailOptions] = useState([]);
const [searchEmail, setSearchEmail] = useState('');
const [selectedEmailAddress, setSelectedEmailAddress] = useState('');



useEffect(() => {
  allemailsend();
}, []);

const allemailsend = () =>{
  const accessToken = localStorage.getItem('access_token');
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
  api.get('/emaillistsend/', {headers,withCredentials: true,})
    .then(response => {
      setEmailOptions(response.data);
    })
    .catch(error => console.error('Error fetching Emails:', error));
}

const handleEmailChange = (event, value) => {
  setSelectedEmail(value);
  setSelectedEmailAddress(value.map(mail => mail.email).join(', '));
};



const handleEmailSentClick = (EmailId) => {
  // setFormDisplay('');
  setEmailType('OUTBOX');
  const params = {mail_id: EmailId}
  api.post(`/retrievesentmails/`,params,{
    headers,
    withCredentials: true,
  })
    .then(response => {
      const emailresultdata = response.data;
      setEmailResults(emailresultdata || []);
    })
    .catch(error => {
      console.error(error);
    });
};

    

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>

      <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        <Tab label="Emails" />
        <Tab label="Email Address List" />
        <Tab label="Email Settings" />
      </Tabs>
      <Divider/>


      {selectedTab === 0 && (
<div>
    <Grid container p={1} sx={{ height:'80vh  !important'}}>
        <Grid item xs={3} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',padding:'10px !important',borderRight:`1px  ${colors.primary[600]} solid`, height:'80vh !important',overflowY:'auto'}}>
<Box position="sticky"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[700]}`}
backgroundColor={colors.primary[700]}>
        <Typography variant='h6' p={1}>Email</Typography>
        <form>
<TextField
        label="Search"
        variant="outlined"
        sx={{ width:'100% !important',marginBottom:'7px' }}
        name="search"
        onChange={handleSearchEmailChange}
        value={searchEmailValue || ""}
      />
</form>
    </Box>


    {searchEmailResults.map((mail, i) => ( 

<Box
display="flex"
justifyContent="space-between"
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
sx={{
  cursor: 'pointer',
  backgroundColor: mail.mail_id === EmailId ? colors.blueAccent[900] : colors.primary[600], 
}}
onClick={() => handleEmailClick(mail.mail_id,mail.email)}
p="2px" marginTop="4px">

<Grid container p={0}>
<Grid  xs={3}>
<EmailIcon sx={{fontSize:'32px',marginLeft:'10px'}}/>
</Grid>
<Grid xs={9}>
<Box sx={{marginTop:'5px'}}>{mail.email} </Box>
</Grid>
</Grid>
</Box>
    ))}




    </Grid>
    <Grid item xs={9} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>

    <Box position="sticky"
    display="flex"
    justifyContent="space-between"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={colors.primary[700]}>
        <Typography variant='h6' p={1}>{EmailName} ( {emailType} )</Typography>         
        
        <Box p={1} sx={{display:formDisplay}}>

        <Button
    variant='contained'
    sx={{
      backgroundColor: '#90ba61',
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      marginRight: "15px",
    }}
    onClick={() => handleEmailClick(EmailId,EmailName)}
  >
    INBOX
  </Button>

        <Button
    variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      marginRight: "15px",
    }}
    onClick={() => handleEmailSentClick(EmailId)}
  >
    OUTBOX
  </Button>

          <Button
    variant='contained'
    sx={{
      backgroundColor: colors.greenAccent[400],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px'
     
    }}
    onClick={() => handleNewEmailClick(EmailId)}
  >
   + New Email
  </Button>



  
  </Box>
    
    </Box>



<Box sx={{height:'70vh !important',overflowY:'auto',p:'5px',paddingBottom:'25px',display:formDisplay}}>

{EmailResults.map((mail, index) => (

<Box
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={colors.primary[600]}
p={2} marginTop="12px"
borderRadius='10px 10px 10px 10px'
width='100%!important'>
  <div>{mail.sender_name ? (<span>Name: <b>{mail.sender_name || [] }</b> |</span>):(<b>{mail.sender_name || [] }</b>)} From: <b>{mail.sender_email || mail.from_email}</b> | Subject: <b>{mail.subject}</b> {mail.copy_type ? (<span> | Copy: <b>{mail.copy_type||[]}</b> | To: <b>{mail.to_email.replace(/[\[\]{}']/g, '')  }</b> </span>):(<b></b>)}</div><br/>
  {mail.content ? (
  <div dangerouslySetInnerHTML={{ __html: mail.content.replace(headerPattern, '') }} />
) : (
  <div>{mail.message}</div>
)}

  <Box marginTop="5px" display="flex" justifyContent="space-between">
    {/* <Box backgroundColor={colors.blueAccent[600]} padding="5px" borderRadius="5px"><b>Reply</b></Box> */}
    <div dangerouslySetInnerHTML={{ __html: mail.date.replace('T', ' ') }} />
    {/* <div>{mail.date.slice(0, -9).replace('T', ' ')}</div> */}
    </Box>
</Box>
))}

</Box>

</Grid>
</Grid>






<Box p={4}></Box>

<Modal
        open={NewEmailOpen}
        // onClose={() => setNewEmailOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={emailstyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Compose Email | From  " {EmailName} "</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewEmailOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleEmailSubmit} id="sendEmailForm">
<Grid container spacing={1}>

<Grid item xs={6}>
          <TextField
              fullWidth   
              type="text"
              label="Subject"
              placeholder="Subject"
              name="subject"
              onChange={handleInputChange}
              required="true"
            />
            <TextField
              type="hidden"
              name="mail_id"
              required="true"
              value={MailIdSend}
              sx={{display:'none!important'}}
            />
   </Grid>


<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Copy"
        name="copy"
        onChange={handleInputChange}
      >
        <MenuItem disabled value="">Select Copy</MenuItem>
        <MenuItem value="none">None</MenuItem>
        <MenuItem value="cc">CC (Carbon Copy)</MenuItem>
        <MenuItem value="bcc">BCC (Blind Carbon Copy)</MenuItem>
      </TextField>
      </Grid>


<Grid item xs={12}>
          <TextField
              fullWidth   
              type="email"
              label="Recipient Email"
              placeholder="Recipient Email"
              name="recipient_email"
              onChange={handleInputChange}
              required="true"
            />
   </Grid>

{/* <Grid item xs={12}>

<Autocomplete
      multiple
      fullWidth 
      id="email-autocomplete"
      options={emailOptions}
      getOptionLabel={(option) => option.email}
      value={selectedEmail}
      onChange={handleEmailChange}
      renderInput={(params) => (
        <TextField
        {...params}
        fullWidth
        label="Select Email Address"
        name="recipient_email"
        onChange={setInputValue(selectedEmailAddress)}
        readOnly
        // required
        />
      )}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          option.email.toLowerCase().includes(state.inputValue.toLowerCase())
        );
      }}
    />
</Grid> */}


   <Grid item xs={12}>
          <TextField
              fullWidth  
              multiline  
              rows={5} 
              type="text"
              label="Message"
              placeholder="Message"
              name="message"
              onChange={handleInputChange}
              required="true"
            />
   </Grid>


   </Grid> 
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white', backgroundColor: colors.blueAccent[600]}}>Send Email</Button>
          </form>

        </div>
        </Box>
        
      </Modal>

</div>)}

{selectedTab === 1 && ( <div>


  <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
  <Box display="flex" justifyContent="right">
  <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      mr: "15px;"
    }}
    onClick={() => setModalEmailAddOpen(true)}
    >
      <AddIcon sx={{ mr: "10px" }} /> Add Email Address</Button>
</Box>
        <Typography variant="body1">
        <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Info</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredEmailData.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.info}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: 'red'}} onClick={() => handleEmailRowClick(row)}><DeleteForeverIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
            <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalEmailItems}
        rowsPerPage={emailcontroller.emailrowsPerPage}
        page={emailcontroller.emailpage}
        onPageChange={handleEmailChangePage}
        onRowsPerPageChange={handleEmailChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />

      </Typography>
      </Box>

      
      <Modal
        open={ModalEmailAddOpen}
        onClose={() => setModalEmailAddOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={emailaddressstyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Email</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setModalEmailAddOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
      <form onSubmit={handleAddressSubmit}>
      <Grid container spacing={1}>

      <Grid item xs={12} display="flex" justifyContent="space-between">
        
      <TextField
              placeholder="Email Info"
              name="info"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              type="text"
            />

      </Grid>

      <Grid item xs={12} display="flex" justifyContent="space-between">
        
        <TextField
                placeholder="Email Address"
                name="email"
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                required="true"
                type="email"
              />
  
        </Grid>

      <Grid item xs={12} display="flex" justifyContent="space-between">
      <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600],cursor:"pointer"}}>Submit</Button>
    </Grid>

      </Grid>
      </form>
        </div>
        </Box>
        
      </Modal>



      <Modal
        open={ModalEmailDeleteOpen}
        onClose={() => setModalEmailDeleteOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={emailaddressstyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Delete Address</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setModalEmailDeleteOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
            <Grid container spacing={1}>

<Box justifyContent="center" textAlign="center">
<h2 id="modal-title">Are You Sure You Want to Delete {selectedItem && (selectedItem.email)}!</h2>
</Box>
          


<Grid item xs={12} display="flex" justifyContent="space-between">
  <Box><Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600],cursor:"pointer"}} onClick={() => setModalEmailDeleteOpen(false)}>No</Button></Box>
  {selectedItem && (<Box><Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: 'red'}} onClick={handleDeleteEmailAddress(selectedItem.id)}>Delete</Button></Box>)}

            
            </Grid>

            </Grid>
        </div>
        </Box>
        
      </Modal>



</div> )}




  </Box>
  );
}

export default Email;