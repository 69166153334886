import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
            100: "#f9fafa",
            200: "#f1f1f2",
            300: "#e7e7e8",
            400: "#d3d4d5",
            500: "#abadaf",
            600: "#807E7E",
            700: "#020231",
            800: "#020620",
            900: "#171a1d", 
        },
                primary: {
        100: "#E7EFF4",
        200: "#D1DEE9",
        300: "#A1B0BF",
        400: "#677381",
        500: "#4E5E73",
        600: "#161B22",
        700: "#0D1117",
        800: "#0A0F1A",
        900: "#060A15",
        },
        
        greenAccent: {
          100: "#C7F9E2",
          200: "#92F4D0",
          300: "#59DFBA",
          400: "#30C0A5",
          500: "#009688",
          600: "#004C5C",
          700: "#004C5C",
          800: "#004757",
          900: "#003448",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#CAFCF3",
          200: "#96FAF0",
          300: "#60F2EF",
          400: "#39DBE5",
          500: "#00B7D4",
          600: "#008FB6",
          700: "#07216C",
          800: "#004D7A",
          900: "#6A81AE",
        },
      }
    : {
        grey: {
          100: "#f9fafa",
          200: "#f1f1f2",
          300: "#e7e7e8",
          400: "#d3d4d5",
          500: "#abadaf",
          600: "#807E7E",
          700: "#D8E5EA",
          800: "#E8F4FC",
          900: "#171a1d",
        },
        primary: {
          900: "#F3F9FB",
          800: "#F3F9FB",
          700: "#F3F9FB",
          600: "#D8E5EA",
          500: "#D8E5EA",
          400: "#181D26",
          300: "#101520",
          200: "#0A0F1A",
          100: "#060A15",
        },
        greenAccent: {
            100: "#003448",
            200: "#004757",
            300: "#00626C",
            400: "#008180",
            500: "#1aa194",
            600: "#54D2CA",
            700: "#1aa194",
            800: "#92F4D0",
            900: "#C7F9E2",   
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
            100: "#CAFCF3",
            200: "#96FAF0",
            300: "#60F2EF",
            400: "#39DBE5",
            500: "#00B7D4",
            600: "#008FB6",
            700: "#07216C",
            800: "#004D7A",
            900: "#9AAFD0",
          },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[900],
              main: colors.grey[900],
              light: colors.grey[100],
            },
            background: {
            default: '#010409',
            },
          }
        : {
            primary: {
              main: colors.primary[900],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[900],
              main: colors.grey[900],
              light: colors.grey[100],
            },
            background: {
                default: '#E4EFF2',
            },
          }),
    },
    typography: {
        fontFamily: 'Roboto Condensed, sans-serif',
    },
  };
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(localStorage.getItem("colorMode") || "light");

  useEffect(() => {
    localStorage.setItem("colorMode", mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
