import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
  } from '@mui/material';

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import { ColorModeContext, tokens } from "../theme";
import TokenExpiration from '../Auth/TokenExpiration';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});

function Calllogs() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
    });

useEffect(() => {
  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/calls/',
        { params: {
          search: searchText,
          page: controller.page,
          size: controller.rowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredData(data.results);
      setTotalItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };
  fetchData();
}, [searchText, controller]);

const handleChangePage = (event, newPage) => {
  setController({
    ...controller,
    page: newPage
  });
};

const handleChangeRowsPerPage = (event) => {
  setController({
    ...controller,
    rowsPerPage: parseInt(event.target.value, 10),
    page: 0
  });
};

const copyPhoneNumber = (call_from, call_to) => {
  if(call_from==(sessionStorage.getItem('extension'))){
    const event = new CustomEvent('copyPhoneNumber', { detail: call_to });
    window.dispatchEvent(event);
  }else{
    const event = new CustomEvent('copyPhoneNumber', { detail: call_from });
    window.dispatchEvent(event);
  }
  
}

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2} sx={{marginBottom:'20px'}}>Call Logs</Typography>

    <Box
        m="40px 0 0 0"
        height="75vh"
        mt="-15px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            // color: colors.greenAccent[300],
            color: colors.primary[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

<div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Destination</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Date</TableCell> 
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.call_from}</TableCell>
                  <TableCell>{row.call_to}</TableCell>
                  <TableCell sx={{color: row.call_duration === 'Missed' ? '#ff5722 !important' : ''}}>{row.call_duration}</TableCell>
                  <TableCell>{row.call_time.slice(0, -13).replace('T', ' ')}</TableCell>
                  <TableCell>{row.call_type}</TableCell>
                  <TableCell><Button variant="contained" sx={{backgroundColor: colors.greenAccent[600] }} key={row.phone_number} onClick={ () => copyPhoneNumber (row.call_from, row.call_to)}><LocalPhoneIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

      </Box>

  </Box>
  );
}

export default Calllogs;