import React, { useEffect,useState } from 'react';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Expiration = () => {
    const [due_date, setDueDate] = useState('');
const [open, setOpen] = useState(false);
const handleClose = (event, reason) => {
if (reason === 'clickaway') {return;}setOpen(false);}; 
const [state, setState] = useState({open: false,vertical: 'top',horizontal: 'center',});
const { vertical, horizontal} = state;

const Alert = React.forwardRef(function Alert(props, ref) {
return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


    const api = axios.create({baseURL: global_link});
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const orgInfo = () => {
        api.get('/organizationinfoclient/', {
            withCredentials: true,
          }).then(response => {
              setDueDate(response.data.due_date);
              const currentDate = new Date(response.data.current_date);
              const targetDate = new Date(response.data.due_date);
              const differenceInMilliseconds = targetDate - currentDate;;
              // Convert milliseconds to weeks
              const differenceInWeeks = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 7));
              if(differenceInWeeks < 1){
                  setOpen(true);
              }else{
                  setOpen(false);
              }
            })
            .catch(error => {
              console.error(error);
            });}


    useEffect(() => {
        orgInfo();

      }, []);

  return (
    <Stack spacing={2} sx={{ width: '100%'}}>
    <Snackbar open={open} autoHideDuration={null} onClose={handleClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
    <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
    Your Subscription Expires On {due_date}
    </Alert>
    </Snackbar>
    </Stack>
  );
};

export default Expiration;