import { Container } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
  } from '@mui/material';
  import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ColorModeContext, tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import CancelIcon from '@mui/icons-material/Cancel';

const api = axios.create({baseURL: global_link});


function WhatsApp() {


const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [formData, setFormData] = useState({});
const [receipient, setReceipient] = useState({});
const [searchChatValue, setSearchChatValue] = useState("");
const [searchChatResults, setSearchChatResults] = useState([]);
const [myextension, setMyExtension] = useState(sessionStorage.getItem("extension"));
const [chatresults, setChatResults] = useState([]);
const [receipitentValue, setReceipitentValue] = useState('');
const [receipitentName, setReceipitentName] = useState('');
const [receipitentId, setReceipitentId] = useState('');
const [formDisplay, setFormDisplay] = useState('none');
const [receipitentColor, setReceipitentColor] = useState(colors.primary[600]);
const [chatChange, setChatChange] = useState('');
const [NewChatOpen, setNewChatOpen] = useState(false);
const chatContainerRef = useRef(null);
const [SendBtn, setSendBtn] = useState('');
const [ContactResults, setContactResults] = useState([]);



const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};

const emailstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };


const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [username, setUsername] = useState('');


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const chatvalue = "";
    setSearchChatValue(chatvalue);
    const params = {search : chatvalue}
    api.get(`/whatsappcontacts/`,{ withCredentials: true})
      .then(response => {
        const chatresultdata = response.data;
        const filteredChatResults = chatresultdata.filter(item =>
          item.whatsapp_id.toLowerCase().includes(chatvalue.toLowerCase())
        );
      
        setSearchChatResults(filteredChatResults);
      })
      .catch(error => {
        console.error(error);
      });
    }, []);


  const handleSearchChatChange = (event) => {
    const chatvalue = event.target.value;
    setSearchChatValue(chatvalue);
    const params = {search : chatvalue}
    api.post(`/whatsappusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const chatresultdata = response.data;
        const filteredChatResults = chatresultdata.filter(item =>
          item.whatsapp_id.toLowerCase().includes(chatvalue.toLowerCase())
        );
        setSearchChatResults(filteredChatResults);
      })
      .catch(error => {
        console.error(error);
      });
  
  };


  const handleChatClick = (vala,valb,valc) => {
    setReceipitentValue(vala);
    sessionStorage.setItem("receipient_value",vala);
    setReceipitentName(valb);
    setReceipitentId(valc);
    setFormDisplay('');
    setReceipitentColor(colors.blueAccent[900])
    const params = {receipient: vala}

    api.post(`/whatsappchat/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const chatresultdata = response.data;
        setChatResults(chatresultdata);
        scrollToBottom();
      })
      .catch(error => {
        console.error(error);
      });
  };


  useEffect(() => {
    let pollTimer;
    const fetchData = () => {
      const params = { receipient: receipitentValue };
      api
        .post(`/whatsappchat/`, params, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          const chatResultData = response.data;
          setChatResults(chatResultData);
          scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const startPolling = () => {
      pollTimer = setInterval(fetchData, 5000);
    };
    if (receipitentValue) {
      startPolling();
    }
    return () => {
      clearInterval(pollTimer);
    };
  }, [receipitentValue, 5000]);


  useEffect(() => {
    let pollTimer;
    const fetchData = () => {
      const chatValue = "";
      const params = { receipient: chatValue };
      api.get(`/whatsappcontacts/`,{ withCredentials: true})
      
        .then((response) => {
          const chatresultdata = response.data;
          const filteredChatResults = chatresultdata.filter(item =>
            item.whatsapp_id.toLowerCase().includes(chatValue.toLowerCase())
          );
          setSearchChatResults(filteredChatResults);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const startPolling = () => {
      pollTimer = setInterval(fetchData, 5000);
    };
      startPolling();
    return () => {
      clearInterval(pollTimer);
    };
  }, [5000]);


  const handleChatSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const chat  = formData.get("chat")
    const accessToken = localStorage.getItem('access_token');
    const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',};
    const params = {phone_number: receipitentValue, message: chat}
    api.post(`/whatsappsendmessage/`, params,{ withCredentials: true})
      .then((response) => {
        // fetchTicketData();
        // setNewTickeEdittOpen(false);
        handleChatClick(receipitentValue,receipitentName,receipitentId);
        setChatChange('');
        scrollToBottom();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handChatChange = (event) => {
    setChatChange(event.target.value);
  }


  const handleModalChatSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const chat  = formData.get("chat")
    const phone_number  = formData.get("phone_number")
    let dialednumber
    const cleanedNumber = phone_number.replace(/\D/g, '');
    if (/^0\d{9}$/.test(cleanedNumber)) {
      const internationalNumber = `254${cleanedNumber.substring(1)}`;
      dialednumber = internationalNumber;
    } else if (/^254\d{9}$/.test(cleanedNumber)) {
      dialednumber = cleanedNumber;
    }

    const params = {phone_number: dialednumber, message: chat}
    setSendBtn('none');
    api.post(`/whatsappsendmessage/`, params,{ withCredentials: true})
      .then((response) => {
        handleChatClick(dialednumber,receipitentName,receipitentId);
        setChatChange('');
        scrollToBottom();
        setSendBtn('');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleContacts = () => {
    api.get('/dialercontants/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setContactResults(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };


  useEffect(() => {
    handleContacts();
  }, []);


  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>



    <Grid container p={1} sx={{ height:'90vh  !important'}}>
        <Grid item xs={4} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',padding:'10px !important',borderRight:`1px  ${colors.primary[600]} solid`, height:'90vh !important',overflowY:'auto'}}>
<Box position="sticky"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[700]}`}
backgroundColor={colors.primary[700]}>
    <Box sx={{display:'flex', justifyContent:'space-between'}}>
    <Typography variant='h6' p={1}><b><WhatsAppIcon sx={{marginTop:'5px',}}/> WhatsApp</b></Typography>

    <Button
    variant='contained'
    sx={{
      backgroundColor: '#128C7E',
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px'
     
    }}
    onClick={() => setNewChatOpen(true)}
  >
   + New Chat
  </Button>
    </Box>
    
<form>
<TextField
        label="Search"
        variant="outlined"
        sx={{ width:'100% !important',marginBottom:'7px' }}
        name="search"
        onChange={handleSearchChatChange}
        value={searchChatValue || ""}
      />
</form>
    </Box>

    {searchChatResults.map((chatlg, i) => ( 



<Box
display="flex"
justifyContent="space-between"
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
sx={{
  cursor: 'pointer',
  backgroundColor: chatlg.whatsapp_id === receipitentValue ? '#128C7E' : colors.primary[600],
  color: chatlg.whatsapp_id === receipitentValue ? 'white' : '', 
}}
onClick={() => handleChatClick(chatlg.whatsapp_id)}
p="2px" marginTop="4px">

<Grid container p={0}>
<Grid  xs={3}>
<AccountCircleIcon sx={{fontSize:'32px',marginLeft:'10px'}}/>
</Grid>
<Grid xs={9}>
<Box sx={{marginTop:'5px'}}>{chatlg.whatsapp_id}</Box>
</Grid>
</Grid>
</Box>
 ))}


    </Grid>
    <Grid item xs={8} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>

    <Box position="sticky"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={colors.primary[700]}>
        {/* <Typography variant='h6' p={1}>{receipitentName} - {receipitentValue}</Typography> */}
        <Typography variant='h6' p={1}><b>{receipitentValue}</b></Typography>
    
    </Box>



<Box sx={{height:'65vh !important',overflowY:'auto',p:'5px',paddingBottom:'25px',display:formDisplay, flexDirection: 'column-reverse!important',}} ref={chatContainerRef}>

{chatresults.map((message, index) => (
  <div>
      <Box
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={message.origin === 'remote' ? '#128C7E' : colors.primary[600]}
color= {message.origin === 'remote' ? 'white' : ''}
p={1} marginTop="12px" padding="9px 20px 9px 20px"
// borderRadius='0px 30px 30px 30px'
borderRadius={message.origin === 'remote' ? '20px 20px 20px 0px' : '20px 20px 0px 20px'}
width='60%!important'
marginLeft={message.origin !== 'local' ? '' : '40%!important'}
>
  {message.text_body}
  <Box textAlign="right !important" display="flex" justifyContent="space-between" fontSize="13px" marginTop="5px"><Box>{message.created_at.slice(0, -22).replace('T', ' ')}</Box>  <Box>{message.created_at.slice(11, -16).replace('T', ' ')}</Box> </Box>
</Box>
  </div>
      ))}

</Box>




<Box
      borderTop={`1px solid ${colors.primary[600]}`}
      backgroundColor={colors.primary[700]}
      sx={{
        zIndex: '999999',
        padding: theme.spacing(1),
        display: formDisplay,
      }}
    >
      <form onSubmit={handleChatSubmit}>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            multiline
            rows={1}
            label="Message"
            placeholder="SMS"
            variant="outlined"
            fullWidth
            value={chatChange}
            onChange={handChatChange}
            name="chat"
            required
            borderRadius='7px 0px 0px 7px'
          />
        </Grid>
        <Grid item xs={2}>
          <Button
          type='submit'
            variant="contained"
            sx={{
              width: '100%',
              height: '56px',
              backgroundColor: colors.greenAccent[500],
              borderRadius: '0px 7px 7px 0px',
              marginLeft: '-5px',
            }}
          >
            <SendIcon/>
          </Button>
        </Grid>
      </Grid>
      </form>
    </Box>



    </Grid>
    



    </Grid>

<Box p={4}></Box>


<Modal
        open={NewChatOpen}
        // onClose={() => setNewEmailOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={emailstyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title"> New Chat</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewChatOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleModalChatSubmit} id="sendEmailForm">
<Grid container spacing={1}>



<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Phone Number"
        name="phone_number"
        onChange={handleInputChange}
        required
      >
        {ContactResults.map((item, index) => (
          <MenuItem value={item.phone_number}>{item.phone_number}</MenuItem>
        ))}
      </TextField>
</Grid>


   <Grid item xs={12}>
          <TextField
              fullWidth  
              multiline  
              rows={5} 
              type="text"
              label="Message"
              placeholder="Message"
              name="message"
              onChange={handleInputChange}
              required="true"
            />
   </Grid>


   </Grid> 
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white', display:SendBtn, backgroundColor: '#128C7E',}}>Send</Button>
          </form>

        </div>
        </Box>
        
      </Modal>

  </Box>
  );
}

export default WhatsApp;