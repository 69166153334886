import { useState, useRef, useEffect } from 'react';

export const useBreakTimer = () => {
  const [breaktimer, setBreakTimer] = useState('00:00:00');
  const intervalBreakRef = useRef(null);

  const startBreakTimer = () => {
    const startTime = new Date();
  
    const updateBreakTimer = () => {
      const currentTime = new Date();
      const elapsedTime = currentTime - startTime;
      const seconds = Math.floor((elapsedTime / 1000) % 60);
      const minutes = Math.floor((elapsedTime / 1000 / 60) % 60);
      const hours = Math.floor((elapsedTime / 1000 / 60 / 60) % 24);
      setBreakTimer(
        `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + seconds}`
      );
    };
    updateBreakTimer();
    // Start the interval to update the timer every second
    intervalBreakRef.current = setInterval(updateBreakTimer, 1000);
  };

  const resetBreakTimer = () => {
    setBreakTimer('00:00:00');
    clearInterval(intervalBreakRef.current);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalBreakRef.current);
    };
  }, []);

  return { breaktimer, startBreakTimer, resetBreakTimer};
};
