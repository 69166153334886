import React, { useEffect } from 'react';
import jwt from "jwt-decode";

const TokenExpiration = () => {
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token!=null) {
      const decodedToken = jwt(token);
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      if (currentTime > expirationTime) {
        localStorage.removeItem('access_token');
        window.location.href = '/login';
        window.location.reload();
      }
    } else {
      window.location.href = '/login';
      window.location.reload();
    }
  }, []);
};

export default TokenExpiration;