import { Container } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,

} from '@mui/material';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import DoughnutChart from "../Components/Doughnut";
import Expiration from '../Components/Expiration';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const api = axios.create({baseURL: global_link});


function Dashboard() {

    const [allcalls, setCalls] = useState(0);
    const [allinbound, setInbound] = useState(0);
    const [alloutbound, setOutbound] = useState(0);
    const [allmissed, setMissed] = useState(0);
    const [allreceived, setReceived] = useState(0);
    const [talktime, setTalkTime] = useState(0);
    const [talktimeout, setTalkTimeOut] = useState(0);
    const [talktimein, setTalkTimeIn] = useState(0);
    const [avtalktime, setAvTalkTime] = useState(0);
    const [avtalktimeout, setAvTalkTimeOut] = useState(0);
    const [avtalktimein, setAvTalkTimeIn] = useState(0);
    
    const [minicalls, setMiniCalls] = useState([]);
    const [minidispositions, setMiniDisp] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
const [selectedTabTwo, setSelectedTabTwo] = useState(0);
const theme = useTheme();
const colors = tokens(theme.palette.mode);



    const accessToken = localStorage.getItem('access_token');
    const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
  
    useEffect(() => {
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
  
      api.get('/allcalls/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setCalls(response.data);
        })
        .catch(error => {
          console.error(error);
        });
      }, [])

      useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
        api.get('/inboundcalls/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            setInbound(response.data);
          })
          .catch(error => {
            console.error(error);
          });
        }, [])
  
        useEffect(() => {
          const accessToken = localStorage.getItem('access_token');
          const headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          };
          api.get('/outboundcalls/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              setOutbound(response.data);
            })
            .catch(error => {
              console.error(error);
            });
  
          }, [])
          useEffect(() => {
            const accessToken = localStorage.getItem('access_token');
            const headers = {
              'Authorization': `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            };
            api.get('/missedcalls/', {
              headers,
              withCredentials: true,
            })
              .then(response => {
                setMissed(response.data);
              })
              .catch(error => {
                console.error(error);
              });

              api.get('/receivedcallscount/', {
                headers,
                withCredentials: true,
              })
                .then(response => {
                  setReceived(response.data);
                })
                .catch(error => {
                  console.error(error);
                });

              
    }, []);

   
    const TotalDuration = useCallback(() => {
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
      api.get('/talktime/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setTalkTime(response.data);
        })
        .catch(error => {
          console.error(error);
        });


        api.get('/incomingtalktime/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            setTalkTimeIn(response.data);
          })
          .catch(error => {
            console.error(error);
          });


          api.get('/outgoingtalktime/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              setTalkTimeOut(response.data);
            })
            .catch(error => {
              console.error(error);
            });
}, []);

const AverageDuration = useCallback(() => {
  
  api.get('/averagetalktime/', {
    headers,
    withCredentials: true,
  })
    .then(response => {
      setAvTalkTime(response.data);
    })
    .catch(error => {
      console.error(error);
    });


    api.get('/averageincomingtalktime/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setAvTalkTimeIn(response.data);
      })
      .catch(error => {
        console.error(error);
      });


      api.get('/averageoutgoingtalktime/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setAvTalkTimeOut(response.data);
        })
        .catch(error => {
          console.error(error);
        });
}, []);

    const fetchMinicalls = useCallback(() => {
      api.get('/minicalls/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setMiniCalls(response.data);
        })
        .catch(error => {
          console.error(error);
        }); 
    }, []);
    
    const fetchMinidispostns = useCallback(() => {
      api.get('/minidispositions/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setMiniDisp(response.data);
        })
        .catch(error => {
          console.error(error);
        }); 
    }, []);

const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTabChangeTwo = (event, newValue) => {
    setSelectedTabTwo(newValue);
  };

  useEffect(() => {
    fetchMinicalls();
    fetchMinidispostns();
    TotalDuration();
    AverageDuration();
  }, []);


  const copyPhoneNumber = (call_from, call_to) => {
    if(call_from === (sessionStorage.getItem('extension'))){
      const event = new CustomEvent('copyPhoneNumber', { detail: call_to });
      window.dispatchEvent(event);
    }else{
      const event = new CustomEvent('copyPhoneNumber', { detail: call_from });
      window.dispatchEvent(event);
    }
    
  }



  const onRecievedCalls = useCallback(() => {
    api.get('/receivedcallscount/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setReceived(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const onMissedCalls = useCallback(() => {
    api.get('/missedcalls/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setMissed(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const onAllCalls = useCallback(() => {
    api.get('/allcalls/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setCalls(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);
  
  const onOutBoundCalls = useCallback(() => {
    api.get('/outboundcalls/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setOutbound(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const onInboundCalls = useCallback(() => {
    api.get('/inboundcalls/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setInbound(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


useEffect(() => {
    const handleDashoardRefresh = () => {
      onRecievedCalls();
      onMissedCalls();
      onAllCalls();
      onOutBoundCalls();
      onInboundCalls();
      TotalDuration();
      AverageDuration();
      fetchMinicalls();
    };
    // Set up an interval to reload functions every 10 seconds
    const refreshInterval = setInterval(handleDashoardRefresh, 10000);
    // If you need to stop the interval (e.g., when unmounting the component), use clearInterval
    return () => clearInterval(refreshInterval);
  }, []);

  const maskValue = (value) => {
    const visibleCharacters = Math.min(3, value.length);
    const maskedCharacters = value.length - visibleCharacters;
    const maskedValue = '*'.repeat(maskedCharacters);
  
    return value.substr(0, visibleCharacters) + maskedValue;
  };

  const handleLogout = () => {
    api.get('/userlogout/',{
      headers,
      withCredentials: true,
    })
      .then(response => {
        localStorage.setItem("loggedIn",false);
        localStorage.removeItem('access_token');
        localStorage.removeItem("calldisabled");
        sessionStorage.removeItem("calldisabled");
        sessionStorage.removeItem('get_register');
        window.location.href="/login";
      })
      .catch(error => {
        console.log(error);
      });

      unRegister();
  };

  const unRegister = () => {
    const event = new CustomEvent('unregisterSession', { detail: 'unregister' });
    window.dispatchEvent(event);
  };

  return (
    <Box sx={{marginTop:'60px', flexGrow: 1, p: 1}}>
      <TokenExpiration/>

      <Box display="flex" justifyContent="left" sx={{marginLeft:'15px'}}>
      <Button
    variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
    }}
    onClick={() => handleLogout(true)}
  >
    <ExitToAppIcon sx={{ mr: "10px" }} />
    Log Out
  </Button>
  </Box>
  
    <Grid container>
        <Grid item  sx={{ flex: '1' }}>
          <Box m={2} sx={{ borderBottom:`3px solid ${colors.blueAccent[600]}`,backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',borderRadius:'5px',padding:'10px !important'}}>
            <Box display='flex' justifyContent='space-between' p={1}>
                <Box><CallIcon sx={{fontSize:'50px',color:`${colors.blueAccent[600]}`}}/> <Typography variant='h6'>All Calls</Typography></Box>
                <Box><Typography variant='h4' fontWeight='700'>{allcalls}</Typography></Box>
            </Box>
            </Box>
        </Grid>
        <Grid item  sx={{ flex: '1' }}>
          <Box m={2} sx={{ borderBottom:`3px solid ${colors.greenAccent[500]}`,backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',borderRadius:'5px',padding:'10px !important'}}>
        <Box display='flex' justifyContent='space-between' p={1}>
                <Box><PhoneCallbackIcon sx={{fontSize:'50px',color:`${colors.greenAccent[500]}`}}/> <Typography variant='h6'>Incoming</Typography></Box>
                <Box><Typography variant='h4' fontWeight='700'>{allinbound}</Typography></Box>
            </Box>
            </Box>
        </Grid>
        <Grid item  sx={{ flex: '1' }}>
          <Box m={2} sx={{ borderBottom:'3px solid #FFB643',backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',borderRadius:'5px',padding:'10px !important'}}>
        <Box display='flex' justifyContent='space-between' p={1}>
                <Box><PhoneForwardedIcon sx={{fontSize:'50px',color:'#FFB643'}}/> <Typography variant='h6'>Outgoing</Typography></Box>
                <Box><Typography variant='h4' fontWeight='700'>{alloutbound}</Typography></Box>
            </Box>
            </Box>
        </Grid>

        <Grid item  sx={{ flex: '1' }}>
          <Box m={2} sx={{ borderBottom:'3px solid #91a832',backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',borderRadius:'5px',padding:'10px !important'}}>
        <Box display='flex' justifyContent='space-between' p={1}>
                <Box><PermPhoneMsgIcon sx={{fontSize:'50px',color:'#91a832'}}/> <Typography variant='h6'>Received</Typography></Box>
                <Box><Typography variant='h4' fontWeight='700'>{allreceived}</Typography></Box>
            </Box>
            </Box>
        </Grid>
          
        <Grid item  sx={{ flex: '1' }}>
          <Box m={2} sx={{ borderBottom:'3px solid #ff5722',backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',borderRadius:'5px',padding:'10px !important'}}>
        <Box display='flex' justifyContent='space-between' p={1}>
                <Box><PhoneMissedIcon sx={{fontSize:'50px',color:'#ff5722'}}/> <Typography variant='h6'>Missed</Typography></Box>
                <Box><Typography variant='h4' fontWeight='700'>{allmissed}</Typography></Box>
            </Box>
            </Box>
        </Grid>


        
 
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={7}>
<Box  m={2} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important',height:'65vh', overflow:'auto'}}>
<Box position="sticky"
    top="-10px"
    backgroundColor={colors.primary[700]}>
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Recent Calls"/>
      </Tabs>

      </Box>

          {selectedTab === 0 && <span>
      <Table sx={{marginTop:"10px"}}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
          <TableCell>Source</TableCell>
            <TableCell>Destination</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Call Type</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
        {minicalls.map((calllogs,i) => (
            <TableRow key={calllogs.id} sx={{color: calllogs.call_duration === 'Missed' ? '#ff5722 !important' : ''}}>
            <TableCell>{maskValue(calllogs.call_from)}</TableCell>
            <TableCell>{maskValue(calllogs.call_to)} </TableCell>
            <TableCell sx={{color: calllogs.call_duration === 'Missed' ? '#ff5722 !important' : ''}}>{calllogs.call_duration}</TableCell>
            <TableCell>{calllogs.call_type}</TableCell>
            <TableCell>{calllogs.call_time.slice(0, -16).replace('T', ' ')}</TableCell>
        </TableRow>
          ))}
        </TableBody>
      </Table>

            </span>}
            </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={5}>
        <Box  m={2} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important',height:'65vh',}}>
<Box position="sticky"
    top="-10px">
<Tabs
  value={selectedTabTwo}
  onChange={handleTabChangeTwo}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab  label="Talk Time" onClick={TotalDuration}/>
        <Tab  label="Average" onClick={AverageDuration}/>
      </Tabs>

      </Box>

            {selectedTabTwo === 0 && <span>
              <Box sx={{justifyContent: 'center',alignItems: 'center',display:'flex'}}>

              <Grid container spacing={2} sx={{marginTop:'20px'}}>
              <Grid xs={12} p={1}><Typography variant='h5' textAlign="center" sx={{fontWeight:'400',marginTop:'10px'}}>Today's Total Talk Time</Typography></Grid>
                <Grid xs={6}><Typography variant='h6' sx={{fontWeight:'600',textAlign:'center',marginTop:'10px'}}> Outgoing <br/> {talktimeout}</Typography></Grid>

                <Grid xs={6}><Typography variant='h6' sx={{fontWeight:'600',textAlign:'center',marginTop:'10px'}}>Incoming <br/> {talktimein}</Typography></Grid>
                <Grid xs={12} p={1}><Typography variant='h1' textAlign="center" sx={{fontWeight:'600',marginTop:'30px',marginBottom:'80px'}}>{ talktime}</Typography></Grid>
              </Grid>
              </Box>
             
            </span>}


            {selectedTabTwo === 1 && <span>
              <Box sx={{justifyContent: 'center',alignItems: 'center',display:'flex'}}>
              <Grid container spacing={2} sx={{marginTop:'20px'}}>
              <Grid xs={12} p={1}><Typography variant='h5' textAlign="center" sx={{fontWeight:'400',marginTop:'10px'}}>Today's Average Talk Time</Typography></Grid>
                <Grid xs={6}><Typography variant='h6' sx={{fontWeight:'600',textAlign:'center',marginTop:'10px'}}> Outgoing <br/> {avtalktimeout}</Typography></Grid>

                <Grid xs={6}><Typography variant='h6' sx={{fontWeight:'600',textAlign:'center',marginTop:'10px'}}>Incoming <br/> {avtalktimein}</Typography></Grid>
                <Grid xs={12} p={1}><Typography variant='h1' textAlign="center" sx={{fontWeight:'600',marginTop:'30px',marginBottom:'80px'}}>{avtalktime}</Typography></Grid>
              </Grid>
              </Box>
             
            </span>}

            </Box>
        </Grid>


        </Grid>

  </Box>
  );
}

export default Dashboard;