import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Button,
  MenuItem,
  Card, 
  CardContent,
  Modal,
  Autocomplete,
  Tabs, 
  Tab,
  Divider,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import JsSIP from 'jssip';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTimer } from '../Components/Timer';


const Monitor = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [activeCalls, setActiveCalls] = useState([]);
    const [endPoints, setEndPoints] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const cuSessionRef = useRef(null);
    const [bargemodal, setBargeModal] = useState(false);
    const [spymodal, setSpyModal] = useState(false);
    const [whispermodal, setWhisperModal] = useState(false);
    const [dialed, setDialed] = useState(false);
    const [userName, setUserName] = useState([]);
    const {timer, startTimer, resetTimer} = useTimer();

    const [userAgent, setUserAgent] = useState(null);

    const remoteAudioRef = useRef(null);

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
    };

    const handleTabChange = (event, newValue) => {
      setSelectedTab(newValue);
    };
    const [mediaStream, setMediaStream] = useState(null);
    const mediaRef = useRef(null);
    const active_calls = () => {
      const accessToken = localStorage.getItem('access_token');
      const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };
    
      api.get('/activecalls/', {
        headers,
        withCredentials: true,
      }).then(response => {
        const initialData = response.data;
        const userNames = [];
    
        const processRow = index => {
          if (index < initialData.length) {
            const row = initialData[index];
            api.get(`/userexteninfo/${row.caller.number}`, {
              headers,
              withCredentials: true,
            }).then(userNameResponse => {
              const userObject = userNameResponse.data.find(item => item.user_name);
              const userName = userObject ? userObject.user_name : 'No Name';
              userNames.push({
                id: row.id,
                userName,
                caller: row.caller.number,
                extension: row.dialplan.exten,
                priority: row.dialplan.priority,
                creationtime: row.creationtime
              });
              processRow(index + 1);
            }).catch(userNameError => {
              console.error('Error fetching user info:', userNameError);
              processRow(index + 1);
            });
          } else {
            setActiveCalls(userNames);
          }
        };
    
        processRow(0);
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    };
    

     
            const device_endpoints = async () => {
              try {
                const accessToken = localStorage.getItem('access_token');
                const headers = {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                };
                const response = await api.get('/deviceendpoints/', {
                        headers,
                        withCredentials: true,
                      }); 
                const initialData = response.data;
          
                const userNamesPromises = initialData.map(async row => {
                  const userNameResponse = await api.get(`/userexteninfo/${row.resource}`, {
                          headers,
                          withCredentials: true,
                        });
                  const userObject = userNameResponse.data.find(item => item.user_name);
                  const userName = userObject ? userObject.user_name : 'No Name';

                  const userObject2 = userNameResponse.data.find(item => item.user_phone);
                  const userPhone = userObject ? userObject2.user_phone : 'No Phone Number';

                  const userObject3 = userNameResponse.data.find(item => item.user_email);
                  const userEmail = userObject ? userObject3.user_email : 'No Email';

                  return {
                    id: row.id,
                    userName,
                    userEmail,
                    userPhone,
                    resource: row.resource,
                    state: row.state
                  };
                });
          
                const resolvedUserNames = await Promise.all(userNamesPromises);
          
                setEndPoints(resolvedUserNames);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
              }
  
          useEffect(() => {
            // active_calls();
            device_endpoints();
          }, []);

const api = axios.create({baseURL: global_link});


useEffect(() => {
  const accessToken = localStorage.getItem('access_token');
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const getUserInfo = async () => {
    try {
      const response = await api.get('/user/', {
        headers,
        withCredentials: true,
      });
      sessionStorage.setItem('extension', response.data.user_extension);
      sessionStorage.setItem('extensionpass', response.data.user_extension_pass);
    } catch (error) {
      console.error(error);
    }
  };

  const getServerInfo = async () => {
    try {
      const response = await api.get('/clientserver/', {
        headers,
        withCredentials: true,
      });
      sessionStorage.setItem('serverip', response.data.server_ip);
      sessionStorage.setItem('serverport', response.data.server_port);
      sessionStorage.setItem('serverspy', response.data.server_spy);
      sessionStorage.setItem('serverbarge', response.data.server_barge);
      sessionStorage.setItem('serverwhisper', response.data.server_whisper);
      sessionStorage.setItem('serverconference', response.data.server_conference);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([getUserInfo(), getServerInfo()]);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);




const spy = sessionStorage.getItem('serverspy');
const barge = sessionStorage.getItem('serverbarge');
const whisper = sessionStorage.getItem('serverwhisper');
const conference = sessionStorage.getItem('serverconference');


useEffect(() => {
  setupJsSIP();
}, []);

const setupJsSIP = () => {
  const extension = sessionStorage.getItem('extension');
  const extensionPass = sessionStorage.getItem('extensionpass');
  const serverPort = sessionStorage.getItem('serverport');
  const serverIP = sessionStorage.getItem('serverip');

  let sys_uri, sys_ext_pass, sys_web_socket;

  if (extension && extensionPass && serverIP && serverPort) {
    sys_uri = `sip:${extension}@${serverIP}`;
    sys_ext_pass = `${extensionPass}`;
    sys_web_socket = `wss://${serverIP}:${serverPort}/ws`;
  } else {
    sys_uri = `sip:1@127.0.0.1:20`;
    sys_ext_pass = `1234`;
    sys_web_socket = `wss://127.0.0.1:20/ws`;
  }

  const wsUri = sys_web_socket;
  const sipUsername = extension;
  const sipPassword = extensionPass;

  const socket = new JsSIP.WebSocketInterface(wsUri);
  const configuration = {
    sockets: [socket],
    uri: `sip:${sipUsername}@${serverIP}`,
    password: sipPassword,
  };
  const ua = new JsSIP.UA(configuration);

  ua.on('registered', () => {
    console.log('Supervisor JsSIP is registered');
    setUserAgent(ua);
  });

  ua.start();
};

const initiateSpyCall = (ua, id, value) => {
  const serverIP = sessionStorage.getItem('serverip');
  // const targetUri = `sip:*551${id}@${serverIP}`;
  const targetUri = `sip:${value}${id}@${serverIP}`;
  const options = {
    eventHandlers: {
      progress: (e) => console.log('Call is in progress:', e),
      failed: (e) => console.error('Call failed:', e),
      ended: (e) => console.log('Call ended:', e),
      confirmed: (e) => console.log('Call confirmed:', e),
    },
    mediaConstraints: { audio: true, video: false },
  };

  const session = ua.call(targetUri, options);
  cuSessionRef.current = session;
  session.on('accepted', (e) => {
    const remoteStreams = session.connection.getRemoteStreams();
    const remoteAudio = remoteStreams[0].getAudioTracks();
    const remoteStream = new MediaStream();
    remoteStream.addTrack(remoteAudio[0]);
    remoteAudioRef.current.srcObject = remoteStream;
    remoteAudioRef.current.play().catch((error) => {
      console.log('Failed to play audio:', error);
    });
  });
};

const handleSpy = (id) => {
  if (userAgent) {
    initiateSpyCall(userAgent, id, spy);
    startTimer();
    setDialed(id);
    setWhisperModal(true);
  }
};

const handleWhisper = (id) => {
  if (userAgent) {
    initiateSpyCall(userAgent, id, whisper);
    startTimer();
    setDialed(id);
    setWhisperModal(true);
  }
};

const handleBarge = (id) => {
  if (userAgent) {
    initiateSpyCall(userAgent, id, barge);
    startTimer();
    setDialed(id);
    setWhisperModal(true);
  }
};


//RECORDING TABLE
const [searchRecordingText, setSearchRecordingText] = useState('');
const [filteredRecordingData, setFilteredRecordingData] = useState([]);
const [totalRecordingItems, setTotalRecordingItems] = useState(0);
const [recordingcontroller, setRecordingController] = useState({
  recordingpage: 1,
  recordingrowsPerPage: 10
});

const fetchRecordingData = async () => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const response = await api.get('/callrecording/',
      { params: {
        search: searchRecordingText,
        page: recordingcontroller.recordingpage,
        size: recordingcontroller.recordingrowsPerPage,
      },
        headers,
        withCredentials: true,
      }
    );
    const data = response.data;
    setFilteredRecordingData(data.results);
    setTotalRecordingItems(data.count);
  } catch (error) {
    console.error(error);
  }
};

useEffect(() => {
  fetchRecordingData();
}, [searchRecordingText, recordingcontroller]);


const handleRecordingChangePage = (event, newPage) => {
  setRecordingController({
    ...recordingcontroller,
    recordingpage: newPage
  });
};

const handleRecordingChangeRowsPerPage = (event) => {
  setRecordingController({
    ...recordingcontroller,
    recordingrowsPerPage: parseInt(event.target.value, 10),
    recordingpage: 1
  });
};

//RECORDING TABLE

useEffect(() => {
  if(spymodal===false || whispermodal===false || bargemodal){
    resetTimer();
  }
}, []);


  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Monitor</Typography>

    <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        <Tab label="Devices" onClick={device_endpoints} />
        <Tab label="Active Calls" onClick={active_calls()} />
        <Tab label="Recordings" />
        <Tab label="Manage" />
      </Tabs>
      <Divider/>
    {selectedTab === 0 && (
      <span>
<Table sx={{marginTop:"20px" }}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Extension</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
        {endPoints.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.userName}</TableCell>
              <TableCell>{row.userPhone}</TableCell>
              <TableCell>{row.userEmail}</TableCell>
              <TableCell>{row.resource}</TableCell>
              <TableCell sx={{ cursor: 'pointer', color: row.state === 'offline' ? 'red !important' : 'green !important' }}>{row.state}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </span>
      )}

     
    {selectedTab === 1 && (
    
    <span>


<Modal
open={spymodal}
onClose={() =>setSpyModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">SPY</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setSpyModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>
<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Spying On {dialed} ...
<Divider/>
{timer}
</Box>
</div>
</Box>
</Modal>



<Modal
open={whispermodal}
onClose={() =>setWhisperModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">Whisper</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setWhisperModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>

<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Whispering On {dialed} ...
<Divider/>
{timer}
</Box>

</div>
</Box>
</Modal>


<Modal
open={bargemodal}
onClose={() =>setBargeModal(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description"
>
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
<h3 id="modal-title">Barge</h3>
<Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setBargeModal(false)} sx={{cursor:"pointer"}}/>
</Box>
</Box>

<Box sx={{display: 'flex', justifyContent: 'center', width: '100%' }}>
Barging On {dialed} ...
<Divider/>
{timer}
</Box>


</div>
</Box>
</Modal>

      <Table sx={{marginTop:"20px"}}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
          <TableCell>Name</TableCell>
            <TableCell>Caller / Extension</TableCell>
            <TableCell>Dialed Number</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
          {activeCalls.map(row => (
            <TableRow key={row.id}>
            <TableCell>{row.userName}</TableCell>
            <TableCell>{row.caller}</TableCell>
            <TableCell>{row.extension}</TableCell>
            <TableCell>{row.priority}</TableCell>
            <TableCell>{row.creationtime.slice(0, -12).replace('T', ' ')}</TableCell>
        <TableCell><Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}} onClick={() => handleSpy(row.caller)}>SPY</Button> | <Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}} onClick={() => handleWhisper(row.caller)}>WHISPER</Button> | <Button variant='contained' sx={{backgroundColor: colors.blueAccent[600], fontWeight:"bold"}} onClick={() => handleBarge(row.caller)}>BARGE</Button></TableCell>
      </TableRow>

          ))}
        </TableBody>
      </Table>
                    </span>


        )}

    {selectedTab === 2 && (
       <div>
              <TextField
        label="Search"
        variant="outlined"
        value={searchRecordingText}
        onChange={(e) => setSearchRecordingText(e.target.value)}
        style={{ marginBottom: '16px',marginTop:'15px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Caller</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredRecordingData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.caller}</TableCell>
                  <TableCell>{row.duration}</TableCell>
                  <TableCell>{row.creationtime}</TableCell>
                  <TableCell>{row.filename}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalRecordingItems}
        rowsPerPage={recordingcontroller.recordingrowsPerPage}
        page={recordingcontroller.recordingpage}
        onPageChange={handleRecordingChangePage}
        onRowsPerPageChange={handleRecordingChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
       </div> 
        )}

    {selectedTab === 3 && (
        <div></div>
        )}

<audio ref={remoteAudioRef} autoPlay />
    </Box>
  );
};

export default Monitor;
