import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import {Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
    Modal,
    MenuItem,
    Divider,
  } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TokenExpiration from '../Auth/TokenExpiration';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Expiration from '../Components/Expiration';


const api = axios.create({baseURL: global_link});


function Settings() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({});
  const [isModalUserOpen, setIsModalUserOpen] = useState(false);
  const [isModalBlastOpen, setIsModalBlastOpen] = useState(false);
  const [isModalUserEditOpen, setIsModalUserEditOpen] = useState(false);
  const [isModalBlastEditOpen, setIsModalBlastEditOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedUserItem, setSelectedUserItem] = useState(null);
  const [selectedBlastItem, setSelectedBlastItem] = useState(null);
  const [selectedEmailItem, setSelectedEmailItem] = useState(null);
  
  const [OrgID, setOrgID] = useState('');
  const [OrgName, setOrgName] = useState('');
  const [OrgEmail, setOrgEmail] = useState('');
  const [OrgPhone, setOrgPhone] = useState('');
  const [OrgPass, setOrgPass] = useState('');
  const [OrgPlan, setOrgPlan] = useState('');
  const [OrgMembers, setOrgMembers] = useState('');
  const [OrgRecurrent, setOrgRecurrent] = useState('');
  const [OrgDue, setOrgDue] = useState('');


  const [ServerID, setServerID] = useState('');
  const [ServerName, setServerName] = useState('');
  const [ServerIP, setServerIP] = useState('');
  const [ServerPort, setServerPort] = useState('');
  const [ServerPrefix, setServerPrefix] = useState('');
  const [ServerSuffix, setServerSuffix] = useState('');
  const [ServerActive, setServerActive] = useState('');
  const [BlastActive, setBlastActive] = useState('');
  const [ServerSpy, setServerSpy] = useState('');
  const [ServerWhisper, setServerWhisper] = useState('');
  const [ServerBarge, setServerBarge] = useState('');
  const [ServerConference, setServerConference] = useState('');
  const [ServerAriPort, setServerAriPort] = useState('');
  const [ServerAriPass, setServerAriPass] = useState('');
  const [ServerAriUsername, setServerAriUsername] = useState('');

  const [SMTPPServer, setSMTPServer] = useState('');
  const [SMTPPort, setSMTPPort] = useState('');
  const [SMTPUseTLS, setSMTPUseTLS] = useState('');
  const [SMTPUseSSL, setSMTPUseSSL] = useState('');
  const [SMTPUsername, setSMTPUsername] = useState('');
  const [SMTPPassword, setSMTPPassword] = useState('');
  const [IMAPServer, setIMAPServer] = useState('');
  const [IMAPPort, setIMAPPort] = useState('');
  const [IMAPUseSSL, setIMAPUseSSL] = useState('');
  const [IMAPUsername, setIMAPUsername] = useState('');
  const [IMAPPassword, setIMAPPassword] = useState('');


  const [ProviderName, setProvider] = useState('');
  const [APIKey, setAPIKey] = useState('');
  const [PartnerID, setPartnerID] = useState('');
  const [ShortCode, setShortCode] = useState('');
  const [EndPoint, setEndPoint] = useState('');

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isModalEmailOpen, setIsModalEmailOpen] = useState(false);
  const [isModalEmailEditOpen, setIsModalEmailEditOpen] = useState(false);

  const [Business, setBusiness] = useState('');
  const [BearerToken, setBearerToken] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [PhoneID, setPhoneID] = useState('');
  const [VerifyToken, setVerifyToken] = useState('');



  const handleSuccessClose = (event, reason) => {
  if (reason === 'clickaway') {return;}setOpenSuccess(false);}; 

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenError(false);}; 


  const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'center',});
  const { vertical, horizontal} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
    


  useEffect(() => {
    setOpenSuccess(false);
    setOpenError(false);
  },[])


const orgInfo = () => {
  api.get('/organizationinfo/', {
    withCredentials: true,
  })
    .then(response => {
        setOrgID(response.data.organization_id);
        setOrgName(response.data.organization_name);
        setOrgEmail(response.data.organization_email);
        setOrgPhone(response.data.organization_phone);
        setOrgPlan(response.data.organization_plan);
        setBlastActive(response.data.ticket_mail_blast);
        setOrgMembers(response.data.organization_members);
        setOrgRecurrent(response.data.recurrent_cost);
        setOrgDue(response.data.due_date);
        setOrgPass(response.data.organization_pass_prefix);
    })
    .catch(error => {
      console.error(error);
    });
  }


  const serverInfo = () => {
    api.get('/serverinfo/', {
      withCredentials: true,
    })
      .then(response => {
          setServerName(response.data.server_name);
          setServerIP(response.data.server_ip);
          setServerPort(response.data.server_port);
          setServerPrefix(response.data.server_prefix);
          setServerSuffix(response.data.server_suffix);
          setServerActive(response.data.server_active);
          setServerSpy(response.data.server_spy);
          setServerWhisper(response.data.server_whisper);
          setServerBarge(response.data.server_barge);
          setServerAriPort(response.data.server_ari_port);
          setServerConference(response.data.server_conference);
          setServerAriPass(response.data.server_ari_password);
          setServerAriUsername(response.data.server_ari_username);
      })
      .catch(error => {
        console.error(error);
      });
    }

    const smsInfo = () => {
      api.get('/smssttingsinfo/', {
        withCredentials: true,
      })
        .then(response => {
            setProvider(response.data.provider_name);
            setAPIKey(response.data.api_key);
            setPartnerID(response.data.partner_id);
            setShortCode(response.data.short_code);
            setEndPoint(response.data.end_point);
        })
        .catch(error => {
          console.error(error);
        });
      }



    useEffect(() => {
      serverInfo();
      smsInfo();
    },[]);
    
    const handleServerNameChange = (event) => {
      setServerName(event.target.value);
    };
  
    const handleServerIPChange = (event) => {
      setServerIP(event.target.value);
    };
  
    const handleServerPortChange = (event) => {
      setServerPort(event.target.value);
    };
  
    const handleServerPrefix = (event) => {
      setServerPrefix(event.target.value);
    };

    const handleServerSuffix = (event) => {
      setServerSuffix(event.target.value);
    };

    const handleServerActive = (event) => {
      setServerActive(event.target.value);
    };

    const handleServerSpy = (event) => {
      setServerSpy(event.target.value);
    };

    const handleServerBarge = (event) => {
      setServerBarge(event.target.value);
    };

    const handleServerWhisper = (event) => {
      setServerWhisper(event.target.value);
    };

    const handleServerConference = (event) => {
      setServerConference(event.target.value);
    };

    const handleServerAriPass = (event) => {
      setServerAriPass(event.target.value);
    };

    const handleServerAriPort = (event) => {
      setServerAriPort(event.target.value);
    };

    const handleServerAriUsername = (event) => {
      setServerAriUsername(event.target.value);
    };



const handleCloseUserModal = () => {
  // setSelectedItem(null);
  setIsModalUserOpen(false);
};

const handleCloseBlastModal = () => {
  // setSelectedItem(null);
  setIsModalBlastOpen(false);
};

const handleCloseBlastEditModal = () => {
  setSelectedBlastItem(null);
  setIsModalBlastEditOpen(false);
};


const handleCloseUserEditModal = () => {
  setSelectedUserItem(null);
  setIsModalUserEditOpen(false);
};

const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };




        const handleOrgNameChange = (event) => {
          setOrgName(event.target.value);
        };
      
        const handleOrgEmailChange = (event) => {
          setOrgEmail(event.target.value);
        };
      
        const handleOrgPhoneChange = (event) => {
          setOrgPhone(event.target.value);
        };

        const handleOrgPassChange = (event) => {
          setOrgPass(event.target.value);
        };
      
        const handleOrgPlanChange = (event) => {
          setOrgPlan(event.target.value);
        };

        const handleBlastActiveChange = (event) => {
          setBlastActive(event.target.value);
        };

        const handleOrgRecurrentChange = (event) => {
          setOrgRecurrent(event.target.value);
        };

        const handleOrgDueChange = (event) => {
          setOrgDue(event.target.value);
        };
       
        const handleOrgMembersChange = (event) => {
          let Plan = 0;
          setOrgMembers(event.target.value);
          const org_value=event.target.value;
          if(OrgPlan=="Basic (1-5)"){
            if(org_value<=10){
              Plan = 2000;
            }else{
              Plan = 3000;
            }
          }
          if(OrgPlan=="Standard (10-20)"){
            if(org_value<=20){
              Plan = 3000;
            }else{
              Plan = 4000;
            }
          }
          if(OrgPlan=="Pro (50-100)"){
            if(org_value<=100){
              Plan = 4000;
            }else{
              Plan = 2500;
            }
          }
          if(OrgPlan=="Enterprice (100+)"){
            if(org_value<=101){
              Plan = 2500;
            }else{
              Plan = 2500;
            }
          }
         const total_cost = OrgMembers * Plan;
         setOrgRecurrent(total_cost);
        };





  const handleSubmitOrganization = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.patch('/addorganization/', formData,{ withCredentials: true})
      .then((response) => {
        orgInfo();
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };

  useEffect(() => { 
    orgInfo();
  },[]);


  const handleSubmitServer = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.patch('/serversettings/', formData,{ withCredentials: true})
      .then((response) => {
        orgInfo();
        setOpenSuccess(true);
        setOpenError(false)
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };

 const handleEditUserSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const id = formData.get('user_id');
  api.put(`/updateuser/${id}`, formData,{ withCredentials: true})
    .then((response) => {
      fetchUserData();
      setIsModalUserEditOpen(false);
      setOpenSuccess(true);
      setOpenError(false);
    })
    .catch((error) => {
      console.error(error);
      setOpenSuccess(false);
      setOpenError(true);
    });
  }

  const handleAddBlastMailEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updatesingleblastmail/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchBlastData();
        setIsModalBlastEditOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
    }

    const handleInputBlastModChange = (event) => {
      const { name, value } = event.target;
      setSelectedBlastItem((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
    };
  


  const handleInputUserModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  const handleEndpointChange = (event) => {
    setEndPoint(event.target.value);
  };
  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };
  const handleApiKeyChange = (event) => {
    setAPIKey(event.target.value);
  };
  const handlePartnerChange = (event) => {
    setPartnerID(event.target.value);
  };
  const handleShortChange = (event) => {
    setShortCode(event.target.value);
  };


  const handleSEmailSeetingsChange = (event) => {
    const { name, value } = event.target;
    setSelectedEmailItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };


  const handleInputUserEditModChange = (event) => {
    const { name, value } = event.target;
    setSelectedUserItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  const handleAddUserSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/adduser/', formData,{ withCredentials: true})
      .then((response) => {
        fetchUserData();
        setIsModalUserOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };

  const handleAddBlastMailSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addblastmail/', formData,{ withCredentials: true})
      .then((response) => {
        fetchBlastData();
        setIsModalBlastOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };


  const handleAddEmailSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addemailsettings/', formData,{ withCredentials: true})
      .then((response) => {
        fetchEmailData();
        setIsModalEmailOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  }


  const handleSMSSettingsSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.patch('/smssttings/', formData,{ withCredentials: true})
      .then((response) => {
        smsInfo();
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };

  const whatsAppInfo = () => {
    api.get('/whatsappinfo/', {
      withCredentials: true,
    })
      .then(response => {
          setBusiness(response.data.name);
          setBearerToken(response.data.bearer);
          setPhoneNumber(response.data.phone_number);
          setPhoneID(response.data.phone_id);
          setVerifyToken(response.data.verify_token);
      })
      .catch(error => {
        console.error(error);
      });
    }

  const handleWhatsAppSettingsSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.patch('/whatsappconfigs/', formData,{ withCredentials: true})
      .then((response) => {
        whatsAppInfo();
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };


  const handleBlastSettingSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // alert(formData.get('status'))
    api.patch('/updateblastmail/', formData,{ withCredentials: true})
      .then((response) => {
        // setBlastActive();
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
      });
  };

  

  const handleEmailSettingsSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.patch('/emailsettings/', formData,{ withCredentials: true})
      .then((response) => {
        fetchEmailData();
        setIsModalEmailEditOpen(false);
        setOpenSuccess(true);
        setOpenError(false);
      })
      .catch((error) => {
        console.error(error);
        setOpenSuccess(false);
        setOpenError(true);
        setIsModalEmailEditOpen(true);
      });
  };

  const handleUserOpenModal = () => {
    // setSelectedItem(item);
    setIsModalUserOpen(true);
  };

  const handleBlastOpenModal = () => {
    // setSelectedItem(item);
    setIsModalBlastOpen(true);
  };

  const handleUserRowClick = (item) => {
    setSelectedUserItem(item);
    setIsModalUserEditOpen(true);
  };

  const handleMailBlastRowClick = (item) => {
    setSelectedBlastItem(item);
    setIsModalBlastEditOpen(true);
  };


  const handleCloseEmailModal = () => {
    setIsModalEmailOpen(false);
  }

  const handleAddEmailModal = () => {
    setIsModalEmailOpen(true);
  }


const handleEmailRowClickModal = (item) => {
    setSelectedEmailItem(item);
    setIsModalEmailEditOpen(true);
  }

const handleCloseEmaileEditModal =() =>{
  setIsModalEmailEditOpen(false);
  }


  const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [controller, setController] = useState({
      page: 1,
      rowsPerPage: 10
    });
  
    // BLAST MAIL TABLE
    const [searchTextMail, setSearchTextMail] = useState('');
    const [pageMail, setPageMail] = useState(0);
    const [rowsPerPageMail, setRowsPerPageMail] = useState(10);
    const [filteredDataMail, setFilteredDataMail] = useState([]);
    const [totalItemsMail, setTotalItemsMail] = useState(0);
    const [controllerMail, setControllerMail] = useState({
      page: 1,
      rowsPerPage: 10
    });
  
  
    const fetchUserData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await api.get('/allusers/',
          { params: {
            search: searchText,
            page: controller.page,
            size: controller.rowsPerPage,
          },
            headers,
            withCredentials: true,
          }
        );
        const data = response.data;
        setFilteredData(data.results);
        setTotalItems(data.count);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchBlastData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await api.get('/getblastmail/',
          { params: {
            search: searchTextMail,
            page: controllerMail.page,
            size: controllerMail.rowsPerPage,
          },
            headers,
            withCredentials: true,
          }
        );
        const data = response.data;
        setFilteredDataMail(data.results);
        setTotalItemsMail(data.count);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      fetchBlastData();
    }, [searchTextMail, controllerMail]);

    const handleChangePageMail = (event, newPage) => {
      setControllerMail({
        ...controllerMail,
        page: newPage
      });
    };
  
    const handleChangeRowsPerPageMail = (event) => {
      setControllerMail({
        ...controllerMail,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1
      });
    };






  
    useEffect(() => {
      fetchUserData();
    }, [searchText, controller]);

    const handleChangePage = (event, newPage) => {
      setController({
        ...controller,
        page: newPage
      });
    };
  
    const handleChangeRowsPerPage = (event) => {
      setController({
        ...controller,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1
      });
    };

    const handleUserDelete = (id) => {
      api.delete(`/deleteuser/${id}`, { withCredentials: true })
        .then((response) => {
          fetchUserData();
          setIsModalUserEditOpen(false);
          setOpenSuccess(true);
          setOpenError(false);
        })
        .catch((error) => {
          setOpenSuccess(false);
          setOpenError(true);
        });
      };


      const handleEmailDelete = (id) => {
        api.delete(`/deletemail/${id}`, { withCredentials: true })
          .then((response) => {
            fetchEmailData();
            setIsModalEmailEditOpen(false);
            setOpenSuccess(true);
            setOpenError(false);
          })
          .catch((error) => {
            setOpenSuccess(false);
            setOpenError(true);
          });
        };


        const handleBlastDelete = (id) => {
          api.delete(`/deleteblastmail/${id}`, { withCredentials: true })
            .then((response) => {
              fetchBlastData();
              setIsModalBlastEditOpen(false);
              setOpenSuccess(true);
              setOpenError(false);
            })
            .catch((error) => {
              setOpenSuccess(false);
              setOpenError(true);
            });
          };


      const runOrgrefresh = () => { 
        orgInfo();
      };
      const runServerrefresh = () => { 
        serverInfo();
      };
      const runUsersrefresh = () => { 
        fetchUserData();
      };
      const runSMSrefresh = () => {
        smsInfo();
      };

      const runWhatsApprefresh = () => {
        whatsAppInfo();
      };



      //EMAIL TABLE
      const [searchEmailText, setSearchEmailText] = useState('');
      const [filteredEmailData, setFilteredEmailData] = useState([]);
      const [totalEmailItems, setTotalEmailItems] = useState(0);
      const [emailcontroller, setEmailController] = useState({
        emailpage: 1,
        emailrowsPerPage: 10
      });
      const [editTemplateOpen, setEditTemplateOpen] = useState(false);
      
    
      const fetchEmailData = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/allemailsettings/',
            { params: {
              search: searchEmailText,
              page: emailcontroller.emailpage,
              size: emailcontroller.emailrowsPerPage,
            },
              headers,
              withCredentials: true,
            }
          );
          const data = response.data;
          setFilteredEmailData(data.results);
          setTotalEmailItems(data.count);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        fetchEmailData();
      }, [searchEmailText, emailcontroller]);
    
    
      const handleEmailRowClick = (item) => {
        setSelectedItem(item);
        setEditTemplateOpen(true);
      };
    
      const handleEmailChangePage = (event, newPage) => {
        setEmailController({
          ...emailcontroller,
          emailpage: newPage
        });
      };
    
      const handleEmailChangeRowsPerPage = (event) => {
        setEmailController({
          ...emailcontroller,
          emailrowsPerPage: parseInt(event.target.value, 10),
          emailpage: 1
        });
      };
    
      //EMAIL TABLES

      const handleInputPasswordChange = (event) => {
        const { name, value } = event.target;
      }

      const handleBusinessChange = (event) => {
        setBusiness(event.target.value);
      };

      const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };

      const handlePhoneIDChange = (event) => {
        setPhoneID(event.target.value);
      };

      const handleBearerTokenChange = (event) => {
        setBearerToken(event.target.value);
      };

      const handleVerifyTokenChange = (event) => {
        setVerifyToken(event.target.value);
      };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Settings  <Box sx={{color:'red'}}>Be Carefull When Editing this Page</Box> </Typography>
    <Grid  spacing={2}  p={1} >
        <Grid item xs={12} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',height:'',borderRadius:'5px',padding:'10px !important'}}>
<Box position="sticky"
    top="-10px">
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Organization" onClick={runOrgrefresh}/>
        <Tab label="Server Settings" onClick={runServerrefresh}/>
        <Tab label="Users"  onClick={runUsersrefresh}/>
        <Tab label="SMS Settings" onClick={runSMSrefresh}/>
        <Tab label="Email Settings" />
        <Tab label="WhatsApp Configs"  onClick={runWhatsApprefresh}/>
        <Tab label="Ticket Blast"  onClick={runWhatsApprefresh}/>
      </Tabs>

      </Box>

      {selectedTab === 0 && <span>
        
        <Grid container spacing={2} p={2}>
</Grid>


     <form onSubmit={handleSubmitOrganization}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12} p={2}><Typography textAlign="left" variant='h6'>Organization Settings</Typography></Grid>

        <Grid item xs={6}>
        <TextField
          label="Organization Name"
          placeholder="Organization Name"
          variant="outlined"
          fullWidth
          name="organization_name"
          value={OrgName}
          onChange={handleOrgNameChange}
          required="true"
          type='text'
        />
        <TextField
          name="organization_id"
          value={OrgID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Organizaion Email"
          placeholder="Organizaion Email"
          variant="outlined"
          fullWidth
          name="organization_email"
          value={OrgEmail}
          onChange={handleOrgEmailChange}
          required="true"
          type='email'
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Organization Phone Number"  
          placeholder="Organization Phone Number"
          variant="outlined"
          fullWidth
          name="organization_phone"
          value={OrgPhone}
          onChange={handleOrgPhoneChange}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>

<TextField
        fullWidth
        select
        label="Organization Plan"
        placeholder="Organization Plan"
        name="organization_plan"
        onChange={handleOrgPlanChange}
        required="true"
        value={OrgPlan}
      >
        <MenuItem disabled value="">Select Plan</MenuItem>
        <MenuItem value="Basic (1-5)">Basic (1-5)</MenuItem>
        <MenuItem value="Standard (10-20)">Standard (10-20)</MenuItem>
        <MenuItem value="Pro (50-100)">Pro (50-100)</MenuItem>
        <MenuItem value="Enterprice (100+)">Enterprice (100+)</MenuItem>
      </TextField>
        </Grid>

        <Grid item xs={3}>
        <TextField
          label="Organization Pass Prefix"
          placeholder="Organization Pass Prefix"
          variant="outlined"
          fullWidth
          name="organization_pass_prefix"
          value={OrgPass}
          onChange={handleOrgPassChange}
          required="true"
          type='text'
        />
        </Grid>


        <Grid item xs={3}>
        <TextField
          label="Organization Members"
          placeholder="Organization Members"
          variant="outlined"
          fullWidth
          name="organization_members"
          value={OrgMembers}
          onChange={handleOrgMembersChange}
          required="true"
          type='number'
        />
        </Grid>


        <Grid item xs={3}>
        <TextField
          label="Total Cost"
          placeholder="Total Cost"
          variant="outlined"
          fullWidth
          value={OrgRecurrent}
          onChange={handleOrgRecurrentChange}
          required="true"
          type='text'
          disabled
        />
        <input
        type="hidden"
        name="recurrent_cost"
        value={OrgRecurrent}
        />
        </Grid>

        <Grid item xs={3}>
        <TextField
          placeholder="Next Due"
          variant="outlined"
          fullWidth
          name="due_date"
          value={OrgDue}
          onChange={handleOrgDueChange}
          required="true"
          type='date'
        />
        </Grid>


        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}>
         Update / Add Organization
        </Button>
        </Grid>
        </Grid>
        </form>
        </span>}

      {selectedTab === 1 && <span>

        <Grid container spacing={2} p={2}>
</Grid>


     <form onSubmit={handleSubmitServer}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12} p={2}><Typography textAlign="left" variant='h6'>Server & Organization Settings</Typography></Grid>

        <Grid item xs={6}>
        <TextField
          label="Organization Name"
          placeholder="Organization Name"
          variant="outlined"
          fullWidth
          name="organization_name"
          value={OrgName}
          required="true"
          type='text'
        />
         <TextField
          name="organization_id"
          value={OrgID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
        <TextField
          name="server_id"
          value={ServerID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Server Name"
          placeholder="Server Name"
          variant="outlined"
          fullWidth
          name="server_name"
          value={ServerName}
          onChange={handleServerNameChange}
          required="true"
          type='text'
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Server IP"
          placeholder="Server IP"
          variant="outlined"
          fullWidth
          name="server_ip"
          value={ServerIP}
          onChange={handleServerIPChange}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Server Port"
          placeholder="Server Port"
          variant="outlined"
          fullWidth
          name="server_port"
          value={ServerPort}
          onChange={handleServerPortChange}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={3}>
<TextField
        fullWidth
        select
        label="Server Prefix"
        name="server_prefix"
        sx={{marginTop:"10px"}}
        value={ServerPrefix}
        onChange={handleServerPrefix}
        required="true"
      >
        <MenuItem disabled value="">Select Prefix</MenuItem>
        <MenuItem value="wss://">WSS</MenuItem>
        <MenuItem value="ws://">WS</MenuItem>
      </TextField>
        </Grid>
        <Grid item xs={3}>
        <TextField
        fullWidth
        select
        label="Server Suffix"
        name="server_suffix"
        sx={{marginTop:"10px"}}
        value={ServerSuffix}
        onChange={handleServerSuffix}
        required="true"
      >
        <MenuItem disabled value="">Select Suffix</MenuItem>
        <MenuItem value="/ws">WS</MenuItem>
        <MenuItem value="/wss">WSS</MenuItem>
        <MenuItem value="/">None</MenuItem>
      </TextField>
        </Grid>

        <Grid item xs={3}>
        <TextField
        fullWidth
        select
        label="Server Active"
        name="server_active"
        sx={{marginTop:"10px"}}
        value={ServerActive}
        onChange={handleServerActive}
        required="true"
      >
        <MenuItem disabled value="">Select</MenuItem>
        <MenuItem value="1">Enabled</MenuItem>
        <MenuItem value="0">Disabled</MenuItem>
      </TextField>
        </Grid>

        <Grid item xs={3}>
        <TextField
        fullWidth
        variant="outlined"
        label="Voice Server Link"
        name="server_spy"
        sx={{marginTop:"10px"}}
        value={ServerSpy}
        onChange={handleServerSpy}
        type="text"
      />
        </Grid>



        <Grid item xs={12}>
        <h4>ARI SETTINGS</h4>
        </Grid>

        <Grid item xs={4}>
        <TextField
        fullWidth
        label="ARI Context (Username)"
        name="server_ari_username"
        sx={{marginTop:"10px"}}
        value={ServerAriUsername}
        onChange={handleServerAriUsername}
        type="text"
      />
        </Grid>

      
        <Grid item xs={4}>
        <TextField
        fullWidth
        label="ARI Port"
        name="server_ari_port"
        sx={{marginTop:"10px"}}
        value={ServerAriPort}
        onChange={handleServerAriPort}
        type="text"
      />
        </Grid>


        <Grid item xs={4}>
        <TextField
        fullWidth
        label="ARI Password"
        name="server_ari_password"
        sx={{marginTop:"10px"}}
        value={ServerAriPass}
        onChange={handleServerAriPass}
        type="text"
      />
        </Grid>



        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
         Update
        </Button>
        </Grid>
        </Grid>
        </form>

            </span>}

            {selectedTab === 2 && <span>
              
              <Grid container spacing={2} p={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography textAlign="left" variant='h6' marginBottom={2} marginTop={2}>Users</Typography>
        <Box><Button variant="contained" sx={{padding:"10px",backgroundColor: colors.blueAccent[600],color:'white',textAlign:'center'}} onClick={handleUserOpenModal}> <AddIcon/> Add User </Button></Box>
        </Grid>

        <Grid item xs={12}>
        <Box
        m="40px 0 0 0"
        height="75vh"
        mt="15px"
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              // color: colors.greenAccent[300],
              color: colors.primary[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
      >

<div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Organization</TableCell>
              <TableCell>Extension</TableCell> 
              <TableCell>Level</TableCell>
              <TableCell>Reg Date</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.user_phone}</TableCell>
                  <TableCell>{row.user_email}</TableCell>
                  <TableCell>{row.user_org}</TableCell>
                  <TableCell>{row.user_extension}</TableCell>
                  <TableCell>{row.user_level}</TableCell>
                  <TableCell>{row.user_reg_date}</TableCell>
                  <TableCell>{row.user_active}</TableCell>
                  <TableCell sx={{display: row.user_level==='admin' ? 'none' : '',}}><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600] }}  onClick={() => handleUserRowClick(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

      </Box>

        </Grid>


        </Grid>






        <Modal
        open={isModalUserOpen} 
        onClose={handleCloseUserModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add User</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalUserOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleAddUserSubmit}>
        
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              placeholder="Name"
              name="user_name"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />

<TextField

              name="user_child_id"
              value={OrgID}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />


</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="user_email"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              name="user_phone"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Password"
              placeholder="Password"
              name="user_pass"
              onChange={handleInputPasswordChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              type="password"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Organization"
              placeholder="Organization"
              name="user_org"
              value={OrgName}
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Extension"
              placeholder="Extension"
              name="user_extension"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Extension Pass"
              placeholder="Extension Pass"
              name="user_extension_pass"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>


<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Level"
    name="user_level"
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserModChange}
    required="true"
  >
    <MenuItem value="">Select Level</MenuItem>
    <MenuItem value="agent">Agent</MenuItem>
    <MenuItem value="supervisor">Supervisor</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Add User</Button>
            </Grid>

            </Grid> 
          </form>
        </div>
        </Box>
        
      </Modal>






      
        <Modal
        open={isModalUserEditOpen} 
        onClose={handleCloseUserEditModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit User</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalUserEditOpen (false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleEditUserSubmit}>
          {selectedUserItem && (
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              placeholder="Name"
              name="user_name"
              onChange={handleInputUserEditModChange}
              value={selectedUserItem.user_name || ""}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />

<TextField
              
              name="user_id"
              onChange={handleInputUserEditModChange}
              value={selectedUserItem.user_id || ""}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />

<TextField

              name="user_child_id"
              value={OrgID}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />


</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="user_email"
              value={selectedUserItem.user_email || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              name="user_phone"
              value={selectedUserItem.user_phone || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>


<Grid item xs={6}>
            <TextField
              label="Organization"
              placeholder="Organization"
              name="user_org"
              // value={OrgName}
              value={selectedUserItem.user_org || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Extension"
              placeholder="Extension"
              name="user_extension"
              value={selectedUserItem.user_extension || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Extension Pass"
              placeholder="Extension Pass"
              name="user_extension_pass"
              value={selectedUserItem.user_extension_pass || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={12}>
            <TextField
              label="Password"
              placeholder="Password"
              name="user_pass"
              value={selectedUserItem.user_pass || ""}
              onChange={handleInputUserEditModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              type="password"
            />
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    select
    label="Level"
    name="user_level"
    value={selectedUserItem.user_level || ""}
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserEditModChange}
    required="true"
  >
    <MenuItem value="">Select Level</MenuItem>
    <MenuItem value="agent">Agent</MenuItem>
    <MenuItem value="supervisor">Supervisor</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Edit User</Button>
            <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:"#ED0800 !important",color:"white"}} onClick={() => handleUserDelete(selectedUserItem.id)}>Delete</Button>
            </Grid>

            </Grid> )}
          </form>
        </div>
        </Box>
        
      </Modal>      
              
              </span>}



              {selectedTab === 3 && <span>
                
                <Grid container spacing={2} p={2}>
               </Grid>


     <form onSubmit={handleSMSSettingsSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12} p={2}><Typography textAlign="left" variant='h6'>SMS Settings</Typography></Grid>

        <Grid item xs={6}>
        <TextField
          label="SMS Endpoint"
          placeholder="SMS Endpoint"
          variant="outlined"
          fullWidth
          name="end_point"
          onChange={handleEndpointChange}
          value={EndPoint}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Provider Name"
          placeholder="Provider Name"
          variant="outlined"
          fullWidth
          name="provider_name"
          onChange={handleProviderChange}
          value={ProviderName}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="API KEY"
          placeholder="Api Key"
          variant="outlined"
          fullWidth
          name="api_key"
          onChange={handleApiKeyChange}
          value={APIKey}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Partner ID"
          placeholder="Partner ID"
          variant="outlined"
          fullWidth
          name="partner_id"
          onChange={handlePartnerChange}
          value={PartnerID}
          required="true"
          type='text'
        />
        </Grid>
        <Grid item xs={6}>
        <TextField
          label="Short Code"
          placeholder="Short Code"
          variant="outlined"
          fullWidth
          name="short_code"
          onChange={handleShortChange}
          value={ShortCode}
          required="true"
          type='text'
        />
        </Grid>


        <Grid item xs={6}>

      <TextField
          label="Organization Name"
          placeholder="Organization Name"
          name="organization_name"
          value={OrgName}
          required="true"
          variant="outlined"
          fullWidth
          type='text'
        />
      <TextField
          name="organization_id"
          value={OrgID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
      </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          // onChange={}
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
         Update
        </Button>
        </Grid>
        </Grid>
        </form>

                </span>}

        {selectedTab === 4 && <span>



          <Box p={2}>
          <Box display="flex" justifyContent="space-between">
          <Typography textAlign="left" variant='h6' marginBottom={2} marginTop={2}>Email Settings</Typography>
          <Box>
          <Button variant="contained" sx={{padding:"10px",backgroundColor: colors.blueAccent[600],color:'white',textAlign:'center'}} onClick={ handleAddEmailModal}> <AddIcon/> Add Email </Button>
          </Box></Box>

          
      <TextField
        label="Search"
        variant="outlined"
        value={searchEmailText}
        onChange={(e) => setSearchEmailText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>SMTP Server</TableCell>
              <TableCell>SMTP Port</TableCell>
              <TableCell>SMTP Use SSL</TableCell>
              <TableCell>SMTP Use TSL</TableCell>
              <TableCell>SMTP Password</TableCell>
              <TableCell>IMAP Server</TableCell>
              <TableCell>IMAP Port</TableCell>
              <TableCell>IMAP Use SSL</TableCell>
              <TableCell>IMAP Password</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredEmailData
              .map((row, index) => (
              <TableRow key={row.id}>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.smtp_server}</TableCell>
              <TableCell>{row.smtp_port}</TableCell>
              <TableCell>{row.smtp_use_ssl}</TableCell>
              <TableCell>{row.smtp_use_tls}</TableCell>
              <TableCell>{row.smtp_password}</TableCell>
              <TableCell>{row.imap_server}</TableCell>
              <TableCell>{row.imap_port}</TableCell>
              <TableCell>{row.imap_use_ssl}</TableCell>
              <TableCell>{row.imap_password}</TableCell>
              <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600] }} onClick={() => handleEmailRowClickModal(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalEmailItems}
        rowsPerPage={emailcontroller.emailrowsPerPage}
        page={emailcontroller.emailpage}
        onPageChange={handleEmailChangePage}
        onRowsPerPageChange={handleEmailChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </Box>




    <Modal
        open={isModalEmailOpen} 
        onClose={handleCloseEmailModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Email ( SMTP | IMAP )</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalEmailOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleAddEmailSubmit}>
        
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="SMTP Server"
              placeholder="SMTP Server"
              name="smtp_server"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="SMTP Port"
              placeholder="SMTP Port"
              name="smtp_port"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Use TLS"
    name="smtp_use_tls"
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserModChange}
  >
    <MenuItem value="">SMTP Use TLS</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Use SSL"
    name="smtp_use_ssl"
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserModChange}
  >
    <MenuItem value="">SMTP Use SSL</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>


<Grid item xs={6}>
            <TextField
              label="SMTP Username"
              placeholder="SMTP Username"
              name="smtp_username"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="SMTP Password"
              placeholder="SMTP Password"
              name="smtp_password"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              type="password"
            />
</Grid>
<Grid item xs={12}><Divider/></Grid>
<Grid item xs={6}>
            <TextField
              label="IMAP Server"
              placeholder="IMAP Server"
              name="imap_server"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="IMAP Port"
              placeholder="IMAP Port"
              name="imap_port"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="IMAP Use SSL"
    name="imap_use_ssl"
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserModChange}
  >
    <MenuItem value="">IMAP Use SSL</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>


<Grid item xs={6}>
            <TextField
              label="IMAP Username"
              placeholder="IMAP Username"
              name="imap_username"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>


<Grid item xs={6}>
            <TextField
              label="IMAP Password"
              placeholder="IMAP Password"
              name="imap_password"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            type="password"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="email"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            type="email"
            />
</Grid>


<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Add Email</Button>
            </Grid>

            </Grid> 
          </form>
        </div>
        </Box>
        
      </Modal>









      <Modal
        open={isModalEmailEditOpen} 
        onClose={handleCloseEmaileEditModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit {selectedEmailItem && (selectedEmailItem.email)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalEmailEditOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
            {selectedEmailItem && (
          <form onSubmit={handleEmailSettingsSubmit}>
        
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="SMTP Server"
              placeholder="SMTP Server"
              name="smtp_server"
              value={selectedEmailItem.smtp_server || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />

<TextField

              name="mail_id"
              // value={OrgID}
              value={selectedEmailItem.mail_id}
              fullWidth
              sx={{display:"none"}}
              required="true"
            />


</Grid>

<Grid item xs={6}>
            <TextField
              label="SMTP Port"
              placeholder="SMTP Port"
              name="smtp_port"
              value={selectedEmailItem.smtp_port || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Use TLS"
    name="smtp_use_tls"
    sx={{ marginTop: "10px" }}
    value={selectedEmailItem.smtp_use_tls}
    onChange={handleSEmailSeetingsChange}
  >
    <MenuItem disabled>SMTP Use TLS</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="Use SSL"
    name="smtp_use_ssl"
    sx={{ marginTop: "10px" }}
    value={selectedEmailItem.smtp_use_ssl}
    onChange={handleSEmailSeetingsChange}
  >
    <MenuItem disabled>SMTP Use SSL</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>


<Grid item xs={6}>
            <TextField
              label="SMTP Username"
              placeholder="SMTP Username"
              name="smtp_username"
              value={selectedEmailItem.smtp_username || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="SMTP Password"
              placeholder="SMTP Password"
              name="smtp_password"
              value={selectedEmailItem.smtp_password || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              type="password"
            />
</Grid>
<Grid item xs={12}><Divider/></Grid>
<Grid item xs={6}>
            <TextField
              label="IMAP Server"
              placeholder="IMAP Server"
              name="imap_server"
              value={selectedEmailItem.imap_server || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="IMAP Port"
              placeholder="IMAP Port"
              name="imap_port"
              value={selectedEmailItem.imap_port || ""}
              onChange={handleSEmailSeetingsChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
  <TextField
    fullWidth
    select
    label="IMAP Use SSL"
    name="imap_use_ssl"
    sx={{ marginTop: "10px" }}
    value={selectedEmailItem.imap_use_ssl || ""}
    onChange={handleSEmailSeetingsChange}
  >
    <MenuItem disabled>IMAP Use SSL</MenuItem>
    <MenuItem value="1">True</MenuItem>
    <MenuItem value="0">False</MenuItem>
  </TextField>
</Grid>


<Grid item xs={6}>
            <TextField
              label="IMAP Username"
              placeholder="IMAP Username"
              name="imap_username"
              onChange={handleSEmailSeetingsChange}
              value={selectedEmailItem.imap_username || ""}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>


<Grid item xs={6}>
            <TextField
              label="IMAP Password"
              placeholder="IMAP Password"
              name="imap_password"
              onChange={handleSEmailSeetingsChange}
              fullWidth
              value={selectedEmailItem.imap_password || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
            type="password"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="email"
              onChange={handleSEmailSeetingsChange}
              fullWidth
              value={selectedEmailItem.email || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            type="email"
            />
</Grid>


<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Edit Email</Button>

            <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:'red'}}  onClick={() => handleEmailDelete(selectedEmailItem.id)}>Delete Email</Button>
            </Grid>

            </Grid> 
          </form>)}
        </div>
        </Box>
        
      </Modal>

          </span>}



          {selectedTab === 5 && <span>
                
                <Grid container spacing={2} p={2}>
               </Grid>

  
     <form onSubmit={handleWhatsAppSettingsSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12} p={2}><Typography textAlign="left" variant='h6'>WhatsApp Settings</Typography></Grid>

        <Grid item xs={6}>
        <TextField
          label="Business Name"
          placeholder="WhatsApp Business Name"
          varianvt="outlined"
          fullWidth
          name="name"
          onChange={handleBusinessChange}
          value={Business}
          required="true"
          type='text'
        />
                <TextField
          name="organization_id"
          value={OrgID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Phone Number"
          placeholder="Phone Number"
          variant="outlined"
          fullWidth
          name="phone_number"
          onChange={handlePhoneNumberChange}
          value={PhoneNumber}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Phone ID"
          placeholder="Phone ID"
          variant="outlined"
          fullWidth
          name="phone_id"
          onChange={handlePhoneIDChange}
          value={PhoneID}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Auth Bearer Token"
          placeholder="Auth Bearer Token"
          variant="outlined"
          fullWidth
          name="bearer"
          onChange={handleBearerTokenChange}
          value={BearerToken}
          required="true"
          type='text'
        />
        </Grid>

        <Grid item xs={6}>
        <TextField
          label="Verify Token"
          placeholder="Verify Token"
          variant="outlined"
          fullWidth
          name="verify_token"
          onChange={handleVerifyTokenChange}
          value={VerifyToken}
          required="true"
          type='text'
        />
        </Grid>



        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
         Update
        </Button>
        </Grid>
        </Grid>
        </form>

                </span>}


                {selectedTab === 6 && <span>

                  <Grid container spacing={2} p={2}>
               </Grid>

  
     <form onSubmit={handleBlastSettingSubmit}>
        <Grid container spacing={2} p={2}>
        <Grid item xs={12} p={2}><Typography textAlign="left" variant='h6'>Ticket Blast Settings</Typography></Grid>

        <Grid item xs={6}>

        </Grid>

        <Grid item xs={12}>
        <TextField
        fullWidth
        select
        label="Ticket Blast"
        placeholder="Ticket Blast"
        name="status"
        onChange={handleBlastActiveChange}
        required="true"
        value={BlastActive}
      >
        <MenuItem disabled>Select</MenuItem>
        <MenuItem value="1">Enable</MenuItem>
        <MenuItem value="0">Disable</MenuItem>
      </TextField>

      <TextField
          name="organization_id"
          value={OrgID}
          required="true"
          type='hidden'
          sx={{display:'none'}}
        />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}
        >
         Update
        </Button>
        </Grid>
        </Grid>
        </form>


        <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
        <Box
        m="40px 0 0 0"
        height="75vh"
        mt="15px"
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              // color: colors.greenAccent[300],
              color: colors.primary[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
      >

<div>

<Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography textAlign="left" variant='h6' marginBottom={2} marginTop={2}>Blast Email</Typography>
        <Box><Button variant="contained" sx={{padding:"10px",backgroundColor: colors.blueAccent[600],color:'white',textAlign:'center'}} onClick={handleBlastOpenModal}> <AddIcon/> Add Blast Emails </Button></Box>
        </Grid>

      <TextField
        label="Search"
        variant="outlined"
        value={searchTextMail}
        onChange={(e) => setSearchTextMail(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredDataMail
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.mail_name}</TableCell>
                  <TableCell>{row.mail_id}</TableCell>
                  <TableCell>{row.mail_category}</TableCell>
                  <TableCell sx={{display: row.user_level==='admin' ? 'none' : '',}}><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600] }}  onClick={() => handleMailBlastRowClick(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItemsMail}
        rowsPerPage={controllerMail.rowsPerPage}
        page={controllerMail.page}
        onPageChange={handleChangePageMail}
        onRowsPerPageChange={handleChangeRowsPerPageMail}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

      </Box>

        </Grid>
        </Grid>


        <Modal
        open={isModalBlastOpen} 
        onClose={handleCloseBlastModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Blast Email</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalBlastOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleAddBlastMailSubmit}>
        
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Email Name"
              placeholder="Email Name"
              name="mail_name"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              placeholder="Email"
              name="mail_id"
              onChange={handleInputUserModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
            <TextField
name="user_child_id"
value={OrgID}
fullWidth
sx={{display:"none"}}
required="true"
/>
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    select
    label="Category"
    name="mail_category"
    sx={{ marginTop: "10px" }}
    onChange={handleInputUserModChange}
    required="true"
  >
    <MenuItem selected disabled>Mail Category</MenuItem>
    <MenuItem value="main">Main</MenuItem>
    <MenuItem value="normal">Normal</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Add Blast Mail</Button>
            </Grid>

            </Grid> 
          </form>
        </div>
        </Box>
        
      </Modal>

      <Modal
        open={isModalBlastEditOpen} 
        onClose={handleCloseBlastEditModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Blast Mail</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalBlastEditOpen (false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>

            <form onSubmit={handleAddBlastMailEditSubmit}>
            {selectedBlastItem && (
        <Grid container spacing={1}>
<Grid item xs={6}>
          <TextField
            label="Email Name"
            placeholder="Email Name"
            name="mail_name"
            value={selectedBlastItem.mail_name || ""}
            onChange={handleInputBlastModChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
          />

<TextField
name="id"
value={selectedBlastItem.id || ""}
fullWidth
sx={{display:"none"}}
required="true"
/>

</Grid>

<Grid item xs={6}>
          <TextField
            label="Email"
            placeholder="Email"
            name="mail_id"
            value={selectedBlastItem.mail_id || ""}
            onChange={handleInputBlastModChange}
            fullWidth
            sx={{marginTop:"10px"}}
            variant="outlined"
            required="true"
          />
          <TextField
name="user_child_id"
value={OrgID}
fullWidth
sx={{display:"none"}}
required="true"
/>
</Grid>

<Grid item xs={12}>
<TextField
  fullWidth
  select
  label="Category"
  name="mail_category"
  sx={{ marginTop: "10px" }}
  onChange={handleInputBlastModChange}
  value={selectedBlastItem.mail_category || ""}
  required="true"
>
  <MenuItem selected disabled>Mail Category</MenuItem>
  <MenuItem value="main">Main</MenuItem>
  <MenuItem value="normal">Normal</MenuItem>
</TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Edit Blast Mail</Button>
          <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:'red'}}  onClick={() => handleBlastDelete(selectedBlastItem.id)}>Delete Blast Email</Button>
          </Grid>


          </Grid> )}
        </form>
        </div>
        </Box>
        
      </Modal> 

                  </span>}

        </Grid>
        </Grid>





      


<Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openError} autoHideDuration={4000} onClose={handleErrorClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
Error Occured !!!
  </Alert>
  </Snackbar>

  <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
  Success 
  </Alert>
  </Snackbar>

  </Stack>

  </Box>
  );
}

export default Settings;