import React, { useState,useEffect } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const Manual = () => {

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{marginTop:'60px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Manual</Typography>


    
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        textColor="secondary"
        indicatorColor="secondary"
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Getting Started" />
        <Tab label="Making Call" />
        <Tab label="RECIEVING CALL" />
      </Tabs>
      <Box sx={{ flexGrow: 1, }}>
        {selectedTab === 0 && (
          <Typography component="div" variant="body1">
            To effectively use a phone system, follow these general guidelines:

Familiarize Yourself with the Features: Take the time to understand the features and functionalities of your phone system. This includes basic functions like making and receiving calls, voicemail, call forwarding, and transferring calls, as well as advanced features such as conference calling, auto-attendant, and call recording. Read the user manual or contact your phone system provider for guidance.

Set Up Personalized Settings: Customize your phone system settings to align with your preferences and work requirements. This may include setting up your voicemail greeting, call forwarding rules, speed dial numbers, and configuring any additional features specific to your needs. Personalizing your settings will help streamline your communication process.

Learn Call Handling Procedures: Understand how to effectively handle incoming and outgoing calls. Be familiar with call routing options, such as transferring calls to the appropriate department or individual, placing calls on hold, and utilizing features like call waiting or call park. Practice proper phone etiquette, including answering calls promptly, speaking clearly, and actively listening to callers.

Utilize Collaboration Tools: Many modern phone systems offer collaboration tools that enhance teamwork and productivity. Explore features like conference calling, screen sharing, instant messaging, and presence indicators. These tools facilitate real-time communication and collaboration among team members, regardless of their physical locations.

Take Advantage of Mobility Features: If your phone system supports mobile connectivity, utilize the available mobile apps or softphone clients. This allows you to make and receive business calls on your mobile device, ensuring accessibility and connectivity even when you are away from your desk. Familiarize yourself with the mobile features and learn how to switch seamlessly between devices.

Monitor and Analyze Call Metrics: If your phone system provides analytics and reporting capabilities, regularly review call metrics and patterns. Analyze call volumes, call duration, response times, and other relevant data to gain insights into communication patterns and identify areas for improvement. Use this information to optimize your phone system usage, allocate resources effectively, and enhance customer service.

Stay Updated with System Upgrades: Keep track of system updates and new feature releases from your phone system provider. Regularly check for software updates, bug fixes, and security patches. Upgrading to the latest version ensures that you have access to the most up-to-date features and improvements.

Train Employees: If you are implementing a phone system within an organization, provide comprehensive training to employees. Ensure they are familiar with the system's features, functionalities, and best practices. Conduct training sessions, share user guides, and encourage ongoing learning to maximize the benefits of the phone system for the entire team.

Remember, specific instructions may vary depending on the type of phone system you are using. Consult your phone system documentation or reach out to your provider for detailed instructions and support tailored to your system.
          </Typography>
        )}
        {selectedTab === 1 && (
          <Typography component="div" variant="body1">
            Content for Tab 2
          </Typography>
        )}
        {selectedTab === 2 && (
          <Typography component="div" variant="body1">
            Content for Tab 3
          </Typography>
        )}
      </Box>
    </Box>

    </Box>
  );
};

export default Manual;
