import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import {useTheme } from "@mui/material";
import { tokens } from "../theme";

const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};


function DoughnutChart() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const api = axios.create({
    baseURL: global_link
    });

    const [allincoming, setIncoming] = useState(0);
    const [alloutgoing, setOutgoing] = useState(0);
    const [allmissed, setMissed] = useState(0);
    const [allnotfound, setNotFound] = useState(0);
    const [allrejected, setRejected] = useState(0);
    const [allreceived, setReceived] = useState(0);


    useEffect(() => {
        api.get('/inboundcalls/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            setIncoming(response.data);
          })
          .catch(error => {
            console.error(error);
          });
  
          api.get('/outboundcalls/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              setOutgoing(response.data);
            })
            .catch(error => {
              console.error(error);
            });
  
            api.get('/missedcalls/', {
              headers,
              withCredentials: true,
            })
              .then(response => {
                setMissed(response.data);
              })
              .catch(error => {
                console.error(error);
              });
  
      api.get('/notfoundcalls/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
          setNotFound(response.data);
        })
        .catch(error => {
          console.error(error);
        });
  
  api.get('/rejectedcalls/', {
    headers,
    withCredentials: true,
  })
    .then(response => {
      setRejected(response.data);
    })
    .catch(error => {
      console.error(error);
    });

    api.get('/receivedcallscount/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        setReceived(response.data);
      })
      .catch(error => {
        console.error(error);
      });
}, [])


const data = {
    labels: ['Incoming','Received','Outgoing','Missed','Rejected','Not Found'],
    datasets: [
        {
            label: 'All Calls',
            data: [allincoming,allreceived,alloutgoing,allmissed,allrejected,allnotfound],
            borderColor: ['rgba(255,206,86,0.001)'],
            backgroundColor: [
            colors.greenAccent[500],
            '#91a832',
            '#FFB643',
            '#FF5722',
            '#5C0347',
            '#F66F8D',
             ],
            pointBackgroundColor: 'rgba(255,206,86,0.2)',
        }
        
    ],

    
}

const options = {
    plugins: {
        title: {
            display: false,
            color:'blue',
            font: {
                size:34
            },
            padding:{
                top:20,
                bottom:30
            },
            responsive:false,
            animation:{
                animateScale: true,
                color: true            }
        },
        maintainAspectRatio: false,
    }
    
}
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
      <div style={{ width: '380px', height: '380px' }}>
      {/* <div style={{ width: '55vw', height: '56vh', marginLeft: '1.5vw' }}> */}
        <Doughnut data={data} options={options} />
      </div>
    </div>
    )
}

export default DoughnutChart;