import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Input,
  Typography,
  Box,
  IconButton,
} from '@mui/material';

function ChatComponent() {
    const [chatMessages, setChatMessages] = useState([]);
    const [message, setMessage] = useState('');
    // const socket = new WebSocket('ws://localhost:8000/ws/chat2/');

    // useEffect(() => {
    //     socket.onmessage = (event) => {
    //         const data = JSON.parse(event.data);
    //         if (data.message) {
    //             setChatMessages([...chatMessages, data.message]);
    //         }
    //     };

    //     socket.onopen = (event) => {
    //         console.log('WebSocket connected');
    //     };

    //     socket.onclose = (event) => {
    //         console.log('WebSocket closed');
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, [chatMessages]);

    // const sendMessage = () => {
    //     if (message.trim() === '') return;
    //     const messageData = { message };
    //     socket.send(JSON.stringify(messageData));
    //     setMessage('');
    // };




    useEffect(() => {
  const chatSocket = new WebSocket(`ws://localhost:8000/ws/chat/`);

  chatSocket.onopen = (event) => {
    const subscribeMessage = {
      action: "fetch_chat",
      recipient: 'b170539c-6699-45d6-ad89-3bd620921134',
      access_token: localStorage.getItem("access_token"),
    };
    chatSocket.send(JSON.stringify(subscribeMessage));
  };

  chatSocket.onmessage = (event) => {
    const data = JSON.parse(event.data);

    if (data.chat_messages) {
      // Handle incoming chat messages
      setChatMessages(data.chat_messages);
      console.log(data.chat_messages);
    }
  };

  const cleanup = () => {
    if (chatSocket.readyState === WebSocket.OPEN) {
      chatSocket.close();
    }
  };

  return cleanup;
}, ['b170539c-6699-45d6-ad89-3bd620921134']);

// const sendChatMessage = (message) => {
//   if (socket && socket.readyState === WebSocket.OPEN) {
//     socket.send(JSON.stringify({ message }));
//   }
// }

    return (
        <Box sx={{ marginTop: '60px', flexGrow: 1, p: 3 }}>
        <Typography variant="h6" paddingBottom={2}>WB Chat</Typography>

        <div className="chat-box">
            Text Message Here
            {chatMessages.map((message, index) => (
            <div key={index}>{message.chat}</div>
            ))}
        </div>

        <div>
            <div>
                {chatMessages.map((message, index) => (
                    <div key={index}>{message}</div>
                ))}
            </div>
            <div>
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                {/* <button onClick={sendMessage}>Send</button> */}
                <button>Send</button>
            </div>
        </div> 
        </Box>
    );
}

export default ChatComponent;
