import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, Typography } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import SideBar from './Components/SideBar';
import SipJsDialBar from './Components/SipJsDialBar';
import Login from './Auth/Login';
import Dashboard from './Pages/Dashboard';
import Calllogs from './Pages/Calllogs';
import Contacts from './Pages/Contacts';
import Dispositions from './Pages/Dispositions';
import Profile from './Pages/Profile';
import Reports from './Pages/Reports';
import Settings from './Pages/Settings';
import SupSettings from './Pages/SupSettings';
import Sms from './Pages/Sms';
import Ticketing from './Pages/Ticketing';
import Monitor from './Pages/Monitor';
import Manual from './Pages/Manual2';
import Chat from './Pages/Chats';
import Email from './Pages/Email';
import Scripts from './Pages/Script';
import WhatsApp from './Pages/Whatsapp';
import Analytics from './Pages/Analytics';
import Breaks from './Pages/Breaks';
import Recordings from './Pages/Recordings';
import LiveDash from './Pages/LiveDash';
import ChatComponent from './Pages/Message'
import Campaigns from './Pages/Campaigns'

import jwt from "jwt-decode";

function App() {
  const [theme, colorMode] = useMode();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userLevel, setUserLevel] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token!=null) {
      const decodedToken = jwt(token);
      const userLevel = decodedToken.user_level;
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      if (currentTime > expirationTime) {
        localStorage.removeItem('access_token');
        setLoggedIn(false);
      }else{
        setUserLevel(userLevel);
        setLoggedIn(true);
      }
    }else{
      setLoggedIn(false);
    }
  }, []);


  const Extension = sessionStorage.getItem('extension');

  return (
    <Router>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
{isLoggedIn ? (
  <>
  <Box sx={{ display: 'flex'}}>

  {Extension !== "112233"  && (
    <SideBar />
)}

  {userLevel === "admin" && (
  <Routes>
    <Route path="/" element={<Dashboard />}  />
    <Route path="/callogs" element={<Calllogs />} />
    <Route path="/dispositions" element={<Dispositions />} />
    <Route path="/contacts" element={<Contacts />} />
    <Route path="/ticketing" element={<Ticketing />} />
    <Route path="/sms" element={<Sms />} />
    <Route path="/reports" element={<Reports />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/monitor" element={<Monitor />} />
    <Route path="/manual" element={<Manual />} />
    <Route path="/chat" element={<Chat />} />
    <Route path="/email" element={<Email />} />
    <Route path="/script" element={<Scripts />} />
    <Route path="/analytics" element={<Analytics />} />
    <Route path="/breaks" element={<Breaks />} />
    <Route path="/jilinde" element={<Campaigns />} />
    <Route path="*" element={<Dashboard />} />
  </Routes>
   )}
  {userLevel === "supervisor" && Extension !== "112233"  && (
    <Routes>
    <Route path="/" element={<Dashboard />}  />
    <Route path="/callogs" element={<Calllogs />} />
    <Route path="/dispositions" element={<Dispositions />} />
    <Route path="/contacts" element={<Contacts />} />
    <Route path="/ticketing" element={<Ticketing />} />
    <Route path="/sms" element={<Sms />} />
    <Route path="/reports" element={<Reports />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/monitor" element={<Monitor />} />
    <Route path="/manual" element={<Manual />} />
    <Route path="/chat" element={<Chat />} />
    <Route path="/email" element={<Email />} />
    <Route path="/script" element={<Scripts />} />
    <Route path="/whatsapp" element={<WhatsApp />} />
    <Route path="/supsettings" element={<SupSettings />} />
    <Route path="/analytics" element={<Analytics />} />
    <Route path="/breaks" element={<Breaks />} />
    <Route path="*" element={<Dashboard />} />
    <Route path="/recordings" element={<Recordings />} />
    <Route path="/message" element={<ChatComponent />} />
    <Route path="/jilinde" element={<Campaigns />} />
  </Routes>
    )}

    {userLevel === "agent" && (
    <Routes>
    <Route path="/" element={<Dashboard />}  />
    <Route path="/callogs" element={<Calllogs />} />
    <Route path="/dispositions" element={<Dispositions />} />
    <Route path="/contacts" element={<Contacts />} />
    <Route path="/ticketing" element={<Ticketing />} />
    <Route path="/sms" element={<Sms />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/chat" element={<Chat />} />
    <Route path="/email" element={<Email />} />
    <Route path="/manual" element={<Manual />} />
    <Route path="/script" element={<Scripts />} />
    <Route path="/whatsapp" element={<WhatsApp />} />
    <Route path="*" element={<Dashboard />} />
    <Route path="/ldash" element={<LiveDash />} />
    <Route path="/message" element={<ChatComponent />} />
    <Route path="/jilinde" element={<Campaigns />} />
  </Routes>
    )}

{userLevel === "supervisor" && Extension == "112233"  && (
  <Routes>
    <Route path="/ldash" element={<LiveDash />} />
    <Route path="*" element={<LiveDash />} />
  </Routes>
  
)}

{userLevel === "agent" && (
<SipJsDialBar/>
)}

{userLevel === "supervisor" && Extension !== "112233"  && (
<SipJsDialBar/>
)}


  </Box>
  </>
 
) : (
  <>
  <Routes>
  <Route path="/" element={isLoggedIn ? (<Navigate to="/login" />) : (<Login setLoggedIn={setLoggedIn} />)}/>
  <Route path="/login" element={isLoggedIn ? (<Navigate to="/" />) : (<Login setLoggedIn={setLoggedIn} />)}/>
  <Route path="*" element={isLoggedIn ? (<Navigate to="/login" />) : (<Login setLoggedIn={setLoggedIn} />)} />
  </Routes>
  </>
)}


        </ThemeProvider>
      </ColorModeContext.Provider>
    </Router>
  );
}

export default App;
