import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
    Modal,
    MenuItem,
  } from '@mui/material';

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { ColorModeContext, tokens } from "../theme";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CancelIcon from '@mui/icons-material/Cancel';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';

const api = axios.create({baseURL: global_link});

function Contact() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [formData, setFormData] = useState({});
    const [formState, setFormState] = useState({});
    const [open, setContactOpen] = useState(false);
    const [csvopen, setContactCSVOpen] = useState(false);
    const [openAlert, setAlertOpen] = useState(false);
    const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setAlertOpen(false);}; 
    const [state, setState] = useState({open: false,vertical: 'top',horizontal: 'center',});
    const { vertical, horizontal} = state;
    const [selectedFile, setSelectedFile] = useState(null);
  
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      // setFormData((prevFormData) => ({
      //   ...prevFormData,
      //   file: file,
      // }));
    };
  
    const handleInputModChange = (event) => {
      const { name, value } = event.target;
      setSelectedItem((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
    };
  
    const handleCSVSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('file', selectedFile);
        api.post('/addcontactcsv/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            console.log('File uploaded successfully:', response.data);
          })
          .catch((error) => {
            console.error('Error uploading file:', error);
          });
    };
  
  
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [controller, setController] = useState({
      page: 1,
      rowsPerPage: 10
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleleContactOpen, setModalDeleleContactOpen] = useState(false);
  
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await api.get('/contacts/',
          { params: {
            search: searchText,
            page: controller.page,
            size: controller.rowsPerPage,
          },
            headers,
            withCredentials: true,
          }
        );
        const data = response.data;
        setFilteredData(data.results);
        setTotalItems(data.count);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      fetchData();
    }, [searchText, controller]);
  
    const handleRowClick = (item) => {
      setSelectedItem(item);
      setIsModalOpen(true);
    };
  
    const handleEdit = (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const id = formData.get('contact_id');
      api.put(`/updatecontact/${id}`, formData,{ withCredentials: true})
        .then((response) => {
          fetchData();
          setIsModalOpen(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
  
    const handleDelete = (id) => {
      api.delete(`/deletecontact/${id}`, { withCredentials: true })
        .then((response) => {
          fetchData();
          setIsModalOpen(false);
          setAlertOpen(false);
        })
        .catch((error) => {
          setAlertOpen(true);
        });
    };
  
  
    const copyPhoneNumber = (phone_number) => {
      const copyNumber = phone_number;
      const event = new CustomEvent('copyPhoneNumber', { detail: copyNumber });
      window.dispatchEvent(event);
    };
  
    const handleCloseModal = () => {
      setSelectedItem(null);
      setIsModalOpen(false);
    };
  
  
    const handleChangePage = (event, newPage) => {
      setController({
        ...controller,
        page: newPage
      });
    };
  
    const handleChangeRowsPerPage = (event) => {
      setController({
        ...controller,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1
      });
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      api.post('/addcontact/', formData,{ withCredentials: true})
        .then((response) => {
          fetchData();
          setContactOpen(false);
          
        })
        .catch((error) => {
          console.error(error);
        });
    };
   const handleContactDelete = () => {
    if(sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin'){
      setModalDeleleContactOpen(true);
    }
    }

    const handleDeleteAllContacts = () => {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      api.post('/deleteallcontacts/', formData,{ headers,withCredentials: true})
        .then((response) => {
          fetchData();
          setModalDeleleContactOpen(false);
          
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const downloadTemplate = () => {
      // Your download logic here
      // For example, creating a download link and triggering a click event
      const downloadLink = document.createElement('a');
      downloadLink.href = '/contact_template.csv';
      downloadLink.download = 'contact_template.csv';
      downloadLink.click();
    };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Contacts</Typography>

    <Box display="flex" justifyContent="right">
    
<Button
    variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setContactOpen(true)}
  >
    <UploadOutlinedIcon sx={{ mr: "10px" }} />
    Add Contact
  </Button>

  <Button
    variant='contained'
    sx={{
      backgroundColor: colors.greenAccent[800],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px",
      display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
    }}
    onClick={downloadTemplate}
  >
    <DownloadOutlinedIcon sx={{ mr: "10px" }} />
    Download Template
  </Button>

  <Button
    variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;",
      display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
    }}
    onClick={() => setContactCSVOpen(true)}
  >
    <UploadOutlinedIcon sx={{ mr: "10px" }} />
    Add Contact (CSV)
  </Button>



  <Button
    variant='contained'
    sx={{
      backgroundColor: 'red',
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      cursor: 'pointer', 
      display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
    }}
    onClick={() => handleContactDelete(true)}
  >
    Delete All Contacts
  </Button>

</Box>


<Box
        m="40px 0 0 0"
        height="75vh"
        mt="15px"
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              // color: colors.greenAccent[300],
              color: colors.primary[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
      >

<div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Label</TableCell> 
              <TableCell>Category</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><DriveFileRenameOutlineIcon/></Button> | <Button variant="contained" sx={{backgroundColor: colors.greenAccent[500] }} key={row.phone_number} onClick={ () => copyPhoneNumber (row.phone_number)}><LocalPhoneIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

      </Box>


      <Modal
        open={open}
        onClose={() => setContactOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Contact</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setContactOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              name="name"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              name="email"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Label"
              name="location"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Category"
        name="category"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem value="">Select a category</MenuItem>
        <MenuItem value="work">Work</MenuItem>
        <MenuItem value="personal">Personal</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
        
      </Modal>




<Modal
        open={csvopen}
        onClose={() => setContactCSVOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Contact CSV</h3>
            <h3 id="modal-title"><Button variant="contained" sx={{marginTop:"-10px!important",backgroundColor: colors.greenAccent[800]}} onClick={downloadTemplate}>Download Template</Button></h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setContactCSVOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleCSVSubmit}>
          <Grid container spacing={1}>
<Grid item xs={12}>
            <TextField
              name="file"
              type="file"
              onChange={handleFileChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Category"
        name="category"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem value="">Select a category</MenuItem>
        <MenuItem value="work">Work</MenuItem>
        <MenuItem value="personal">Personal</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>






      <Modal
        open={isModalDeleleContactOpen}
        onClose={() => setModalDeleleContactOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Delete Contacts</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setModalDeleleContactOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <Grid container spacing={1}>

<Box justifyContent="center" textAlign="center">
<h2 id="modal-title">Are You Sure You Want to Delete All Contacts!</h2>
</Box>
          


<Grid item xs={12} display="flex" justifyContent="space-between">
  <Box><Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600],cursor:"pointer"}} onClick={() => setModalDeleleContactOpen(false)}>No</Button></Box>
  {sessionStorage.getItem("user_level") === "supervisor" && (
        <Box><Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: 'red'}} onClick={handleDeleteAllContacts}>Delete</Button></Box> )}
            
            </Grid>

            </Grid>
        </div>
        </Box>
        
      </Modal>



      <Modal
        open={isModalOpen} 
        onClose={handleCloseModal}

        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">{selectedItem && (selectedItem.name)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleEdit}>
          {selectedItem && (
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              name="name"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              value={selectedItem.name || ""}
              required="true"
            />

<TextField    type="hidden"
              name="contact_id"
              value={selectedItem.id}
              style={{ display: 'none' }}
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              name="phone_number"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              value={selectedItem.phone_number || ""}
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              name="email"
              onChange={handleInputModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              value={selectedItem.email || ""}
            />
</Grid>

<Grid item xs={6}>
            <TextField
              name="location"
              onChange={handleInputModChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              value={selectedItem.location || ""}
              required="true"
            />
</Grid>


<Grid item xs={12}>
  <TextField
    fullWidth
    select
    label="Category"
    name="category"
    sx={{ marginTop: "10px" }}
    value={selectedItem.category || ""}
    onChange={handleInputModChange}
    required="true"
  >
    <MenuItem value="">Select a category</MenuItem>
    <MenuItem value="work">Work</MenuItem>
    <MenuItem value="personal">Personal</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Update</Button>
            {sessionStorage.getItem("user_level") === "supervisor" && (<Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:"#ED0800 !important",color:"white"}} onClick={() => handleDelete(selectedItem.id)}>Delete</Button>)}
            </Grid>

            </Grid> 
            )}
          </form>
        </div>
        </Box>
        
      </Modal> 

  </Box>
  );
}

export default Contact;