import { Container } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SendIcon from '@mui/icons-material/Send';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
  } from '@mui/material';
  import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { ColorModeContext, tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});


function Chats() {


const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [formData, setFormData] = useState({});
const [receipient, setReceipient] = useState({});
const [searchChatValue, setSearchChatValue] = useState("");
const [searchChatResults, setSearchChatResults] = useState([]);
const [myextension, setMyExtension] = useState(sessionStorage.getItem("extension"));
const [chatresults, setChatResults] = useState([]);
const [receipitentValue, setReceipitentValue] = useState('');
const [receipitentName, setReceipitentName] = useState('');
const [receipitentId, setReceipitentId] = useState('');
const [formDisplay, setFormDisplay] = useState('none');
const [receipitentColor, setReceipitentColor] = useState(colors.primary[600]);
const [chatChange, setChatChange] = useState('');
const chatContainerRef = useRef(null);


const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};



const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [username, setUsername] = useState('');


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    const chatvalue = "";
    setSearchChatValue(chatvalue);
    const params = {search : chatvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const chatresultdata = response.data;
        const filteredChatResults = chatresultdata.filter(item =>
          item.user_name.toLowerCase().includes(chatvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(chatvalue.toLowerCase())
        );
      
        setSearchChatResults(filteredChatResults);
      })
      .catch(error => {
        console.error(error);
      });
    }, []);


  const handleSearchChatChange = (event) => {
    const chatvalue = event.target.value;
    setSearchChatValue(chatvalue);
    const params = {search : chatvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const chatresultdata = response.data;
        const filteredChatResults = chatresultdata.filter(item =>
          item.user_name.toLowerCase().includes(chatvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(chatvalue.toLowerCase())
        );
        setSearchChatResults(filteredChatResults);
      })
      .catch(error => {
        console.error(error);
      });
  
  };


  const handleChatClick = (vala,valb,valc) => {
    setReceipitentValue(vala);
    setReceipitentName(valb);
    setReceipitentId(valc);
    setFormDisplay('');
    setReceipitentColor(colors.blueAccent[900])
    const params = {receipient: valc}
    api.post(`/userschats/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const chatresultdata = response.data;
        setChatResults(chatresultdata);
        scrollToBottom();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleChatSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const chat  = formData.get("chat")
    const params = {receipient: receipitentId, chat: chat}
    api.post(`/addchat/`, params,{ withCredentials: true})
      .then((response) => {
        // fetchTicketData();
        // setNewTickeEdittOpen(false);
        handleChatClick(receipitentValue,receipitentName,receipitentId);
        setChatChange('');
        scrollToBottom();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handChatChange = (event) => {
    setChatChange(event.target.value);
  }

  useEffect(() => {
    let pollTimer;
    const fetchData = () => {
      const params = { receipient: receipitentId };
      api
        .post(`/userschats/`, params, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          const chatResultData = response.data;
          setChatResults(chatResultData);
          scrollToBottom();
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const startPolling = () => {
      pollTimer = setInterval(fetchData, 5000);
    };
    if (receipitentId) {
      startPolling();
    }
    return () => {
      clearInterval(pollTimer);
    };
  }, [receipitentId, 5000]);


  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>



    <Grid container p={1} sx={{ height:'90vh  !important'}}>
        <Grid item xs={4} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',padding:'10px !important',borderRight:`1px  ${colors.primary[600]} solid`, height:'90vh !important',overflowY:'auto'}}>
<Box position="sticky"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[700]}`}
backgroundColor={colors.primary[700]}>
        <Typography variant='h6' p={1}>Chats</Typography>
<form>
<TextField
        label="Search"
        variant="outlined"
        sx={{ width:'100% !important',marginBottom:'7px' }}
        name="search"
        onChange={handleSearchChatChange}
        value={searchChatValue || ""}
      />
</form>
    </Box>

    {searchChatResults.map((chatlg, i) => ( 



<Box
display="flex"
justifyContent="space-between"
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
sx={{
  cursor: 'pointer',
  display: chatlg.user_extension === myextension ? 'none' : '',
  color: chatlg.user_level === 'supervisor' ? '#03a9fc' : '',
  backgroundColor: chatlg.user_id === receipitentId ? colors.blueAccent[900] : colors.primary[600], 
}}
onClick={() => handleChatClick(chatlg.user_extension,chatlg.user_name,chatlg.user_id)}
p="2px" marginTop="4px">

<Grid container p={0}>
<Grid  xs={3}>
<PersonOutlinedIcon sx={{fontSize:'32px',marginLeft:'10px'}}/>
</Grid>
<Grid xs={9}>
<Box sx={{marginTop:'5px'}}>{chatlg.user_name} - {chatlg.user_extension}</Box>
</Grid>
</Grid>
</Box>
 ))}


    </Grid>
    <Grid item xs={8} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>

    <Box position="sticky"
    top="-10px"
    borderTop={`1px solid ${colors.primary[700]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={colors.primary[700]}>
        <Typography variant='h6' p={1}>{receipitentName} - {receipitentValue}</Typography>
    
    </Box>



<Box sx={{height:'65vh !important',overflowY:'auto',p:'5px',paddingBottom:'25px',display:formDisplay,}} ref={chatContainerRef}>

{chatresults.map((message, index) => (
  <div>
      <Box
alignItems="center"
borderTop={`1px solid ${colors.primary[600]}`}
borderBottom={`1px solid ${colors.primary[600]}`}
backgroundColor={message.chat_sender === receipitentId ? colors.blueAccent[900] : colors.primary[600]}
marginTop="12px" p={1} padding="7px 20px 7px 20px"
// borderRadius='0px 30px 30px 30px'
borderRadius={message.chat_sender === receipitentId ? '20px 20px 20px 0px' : '20px 20px 0px 20px'}
width='60%!important'
marginLeft={message.chat_sender === receipitentId ? '' : '40%!important'}
>
  {message.chat}
  <Box textAlign="right !important" display="flex" justifyContent="space-between" fontSize="13px" marginTop="8px"><Box>{message.chat_time.slice(0, -22).replace('T', ' ')}</Box>  <Box>{message.chat_time.slice(11, -16).replace('T', ' ')}</Box> </Box>
</Box>
  </div>
      ))}

</Box>




<Box
      borderTop={`1px solid ${colors.primary[600]}`}
      backgroundColor={colors.primary[700]}
      sx={{
        zIndex: '999999',
        padding: theme.spacing(1),
        display: formDisplay,
      }}
    >
      <form onSubmit={handleChatSubmit}>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            multiline
            rows={1}
            label="Message"
            placeholder="SMS"
            variant="outlined"
            fullWidth
            value={chatChange}
            onChange={handChatChange}
            name="chat"
            required
            borderRadius='7px 0px 0px 7px'
          />
        </Grid>
        <Grid item xs={2}>
          <Button
          type='submit'
            variant="contained"
            sx={{
              width: '100%',
              height: '56px',
              backgroundColor: colors.greenAccent[500],
              borderRadius: '0px 7px 7px 0px',
              marginLeft: '-5px',
            }}
          >
            <SendIcon/>
          </Button>
        </Grid>
      </Grid>
      </form>
    </Box>



    </Grid>
    



    </Grid>

<Box p={4}></Box>

  </Box>
  );
}

export default Chats;