
import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TextField,
    Modal,
    MenuItem,
  } from '@mui/material';

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { ColorModeContext, tokens } from "../theme";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CancelIcon from '@mui/icons-material/Cancel';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';

const api = axios.create({baseURL: global_link});

function Campaigns() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [formData, setFormData] = useState({});
    const [formState, setFormState] = useState({});
    const [open, setContactOpen] = useState(false);
    const [dispacthopen, setDispatchOpen] = useState(false);
    const [openAlert, setAlertOpen] = useState(false);
    const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setAlertOpen(false);};
    const [state, setState] = useState({open: false,vertical: 'top',horizontal: 'center',});
    const { vertical, horizontal} = state;
    const [selectedFile, setSelectedFile] = useState(null);
    const [DispatchTeamData, setDispatchTeamData] = useState([]);

    
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [controller, setController] = useState({
      page: 1,
      rowsPerPage: 10
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleleContactOpen, setModalDeleleContactOpen] = useState(false);


    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };


    const handleInputModChange = (event) => {
      const { name, value } = event.target;
      setSelectedItem((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
    };

    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        };
        const response = await api.get('/jilindedata/',
          { params: {
            search: searchText,
            page: controller.page,
            size: controller.rowsPerPage,
          },
            headers,
            withCredentials: true,
          }
        );
        const data = response.data;
        setFilteredData(data.results);
        setTotalItems(data.count);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      fetchData();
    }, [searchText, controller]);


    const copyPhoneNumber = (phone_number) => {
      const copyNumber = phone_number;
      const event = new CustomEvent('copyPhoneNumber', { detail: copyNumber });
      window.dispatchEvent(event);
    };

    const handleChangePage = (event, newPage) => {
      setController({
        ...controller,
        page: newPage
      });
    };

    const handleChangeRowsPerPage = (event) => {
      setController({
        ...controller,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 1
      });
    };

    useEffect(() => {
      const handleDataRefresh = () => {
        fetchData()
      };
      const refreshInterval = setInterval(handleDataRefresh, 10000);
      return () => clearInterval(refreshInterval);
    }, []);
    const handleLocationClick = (location) => {
      window.open(`${location}`, '_blank');
    };


  const handleDispatchClick = (item) => {
    setSelectedItem(item);
    setDispatchOpen(true);
    fetchDispatchTeamData();
  };

  const fetchDispatchTeamData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/getjilindedispatchteam/', {
        headers,
        withCredentials: true,
      });
      setDispatchTeamData(response.data.data);
    } catch (error) {
      console.error(error); 
    }
  };

  useEffect(() => {
    fetchDispatchTeamData();
  }, []);


  const handleDispatchSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const params = {id: formData.get("id"), alarmId: formData.get("alarmId"), responderId: formData.get("responderId")}
    api.patch('/submitjilindedispatch/', params,{ withCredentials: true})
      .then((response) => {
        setDispatchOpen(false);
        fetchData();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Jilinde</Typography>

<Box
        m="40px 0 0 0"
        height="75vh"
        mt="15px"
        sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              // color: colors.greenAccent[300],
              color: colors.primary[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.primary[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
      >

<div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Cause</TableCell>
              <TableCell>Alarm Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id} sx={{backgroundColor: row.status === 1 ? '' : '#FF9494'}}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleLocationClick(row.location)}>GET LOCATION</Button></TableCell>
                  <TableCell>{row.cause}</TableCell>
                  <TableCell>{row.alarm_id}</TableCell>
                  <TableCell sx={{ color: row.status === 1 ? '#4BB543' : 'red', fontWeight: 'bold',}}>{row.status === 1 ? 'Dispatched' : 'Pending'}</TableCell>
                  <TableCell>{row.date.slice(0, -13).replace('T', ' ')}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600], display: row.status === 1 ? 'none' : ''}} onClick={() => handleDispatchClick(row)}><AssistantDirectionIcon/></Button> <span style={{ display: row.status === 1 ? 'none' : 'inline' }}>|</span>
                  <Button variant="contained" sx={{backgroundColor: colors.greenAccent[500] }} key={row.phone_number} onClick={ () => copyPhoneNumber (row.phone_number)}><LocalPhoneIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>


    <Modal
        open={dispacthopen}
        onClose={() => setDispatchOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Dispatch Panic</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setDispatchOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleDispatchSubmit}>
          {selectedItem && (
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              name=""
              type="text"
              onChange={handleInputChange}
              fullWidth
              value={selectedItem.name || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readOnly
            />
</Grid>

<Grid item xs={6}>
            <TextField
              name=""
              type="text"
              onChange={handleInputChange}
              fullWidth
              value={selectedItem.phone_number || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readOnly
            />
</Grid>

<Grid item xs={6}>
            <TextField
              name=""
              type="text"
              onChange={handleInputChange}
              fullWidth
              value={selectedItem.cause || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readOnly
            />
</Grid>
<Grid item xs={6}>
            <TextField
              name="alarmId"
              type="text"
              onChange={handleInputChange}
              fullWidth
              value={selectedItem.alarm_id || ""}
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
              readOnly
            />
            <TextField
              name="id"
              type="hidden"
              onChange={handleInputChange}
              sx={{display:"none"}}
              value={selectedItem.id}
              readOnly
            />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Dispatch Team ( Name | Phone Number | Response Code | Response Type)"
        name="responderId"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
<MenuItem disabled>Select Dispatch Team</MenuItem>
{DispatchTeamData.map((row) => (
        <MenuItem key={row.responderID} value={row.responderID}>
          {row.fullnames} | {row.phone} | {row.responseCode} | {row.responseType}
        </MenuItem>
      ))}
      </TextField>
</Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Dispatch</Button>
            </Grid>

            </Grid> )}
          </form>
        </div>
        </Box>
      </Modal>

      </Box>
  </Box>
  );
}

export default Campaigns;

